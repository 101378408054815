import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import InfoCard from '../InfoCard/InfoCard.js';
import { Spin } from 'antd';
import { ROUTE_OFFICE_VIEW } from '../../AppRoutes.js'
import { action,
         signal,
         READ_XERO_AUTH_STATE,
         AUTHORIZE_XERO,
         AUTHORIZE_XERO_FOR_OFFICE } from '../../state/actions.js';
import { jwtValid } from "../../utils/jwt.js";
import { ROUTE_HOME } from '../../AppRoutes.js';

const AuthorizeXero = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectState = searchParams.get('state');
  const context = redirectState.split('^')[0];

  const authState = useSelector(state => state.xero.authState);
  const jwt = useSelector(state => state.accessControl.jwt);

  const storeReadInProgress = (authState === '');

  const xeroAuthorizedSignal = useSelector(state => {
    if (!state.signal) {
      return null;
    }

    if (state.signal.tag !== 'xero-authorized') {
      return null;
    }

    return state.signal;
  });

  useEffect(() => {
    dispatch(action(READ_XERO_AUTH_STATE));
  }, [dispatch]);

  // Login Case
  useEffect(() => {
    if (context === 'login') {
      if (jwtValid(jwt)) {
        navigate(ROUTE_HOME);
      } else {
        const postBody = {
          url: window.location.href,
          context: context,
          state: redirectState
        };

        dispatch(action(AUTHORIZE_XERO, postBody));
      }
    }
  }, [jwt, navigate]);

  // Authorize Xero account for Office
  useEffect(() => {
    if (context !== 'login') {
      if (xeroAuthorizedSignal === null) {
        const postBody = {
          url: window.location.href,
          officeId: context,
          state: redirectState
        };

        dispatch(action(AUTHORIZE_XERO_FOR_OFFICE, postBody));
      } else {
        const url = `${ROUTE_OFFICE_VIEW}?id=${context}`
        navigate(url);
      }

    }
  }, [dispatch, context, xeroAuthorizedSignal]);

  if (storeReadInProgress) {
    return null;
  }

  if (redirectState !== authState) {
    return (
      <InfoCard>
        <p>We've encountered a problem authorizing with Xero. Please contact support.</p>
      </InfoCard>
    );
  }

  return (
    <InfoCard>
      <Spin tip="Authorizing with XERO" size="large">
      </Spin>
    </InfoCard>
  );
};

export default AuthorizeXero;
