import React from "react";
import { Typography } from 'antd';

const Settings = () => {
  const { Title } = Typography;

  return (
    <div>
      <Title level={3}>Settings</Title>
    </div>
  );
};

export default Settings;
