import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  action,
  STORE_AUTH_STATE
} from '../../state/actions.js';

const LoginMicrosoft = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const state = uuid();
    dispatch(action(STORE_AUTH_STATE, { state }));
  }, [dispatch]);

  const state = useSelector(state => state.accessControl.authState);

  const params = {
    client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    response_type: 'code',
    redirect_uri: process.env.REACT_APP_MICROSOFT_REDIRECT_URL,
    response_mode: 'query',
    scope: 'openid email',
    state: state
  };

  const urlParams = new URLSearchParams(params).toString();
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${urlParams}`;

  return (
    <Tooltip title="Sign In with Microsoft">
      <a href={url} target="_self">
        <img alt="Sign In with Microsoft." src={require('./login-microsoft.png')}
          style={{ width: '48px', height: '48px' }} />
      </a>
    </Tooltip>
  );
};

export default LoginMicrosoft;
