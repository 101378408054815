import React from "react";
import {  Typography, Col, Row, Button  } from 'antd';
import { ROUTE_OFFICE_ADD, ROUTE_OFFICE_OVERVIEW } from '../../AppRoutes.js';
import { useNavigate } from 'react-router-dom';

const UserHome = () => {
  const { Title } = Typography;

  const navigate = useNavigate();

  const onClickAddOffice = () => {
    navigate(ROUTE_OFFICE_ADD);
  };

  const onClickViewOffices = () => {
    navigate(ROUTE_OFFICE_OVERVIEW);
  };

  return (
    <div>
      <Title level={3}>REAP Dashboard Integrator</Title>
      <Row gutter={48} style={{marginTop: '40px'}}>
        <Col span={6}>
          <Title level={5}>Add Office</Title>
          <p>To add a new office and attach a XERO account to it, click here.</p>
          <Button type="primary" style={{width: '120px'}} onClick={onClickAddOffice}>Add Office</Button>
        </Col>
        <Col span={6}>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
      </Row>
      <Row gutter={64} style={{marginTop: '40px'}}>
        <Col span={6}>
          <Title level={5}>View Offices</Title>
          <p>To view and make changes to existing offices, click here.</p>
          <Button type="primary" style={{width: '120px'}} onClick={onClickViewOffices}>View Offices</Button>
        </Col>
        <Col span={6}>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
      </Row>
    </div>
  );
};

export default UserHome;
