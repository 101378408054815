import React from 'react';
import { Button, Select, Row, Col } from 'antd';
import { withoutId } from '../../utils/data.js';

const { Option } = Select;

function AccountBasedProRataSplitSubMenu(props) {

  const split = props.row.split;
  const splitMeta = JSON.parse(split.split_meta);

  function onMappingChanged(division, value, type) {
    const checkedValue = value == '' ? 'none' : value
    const meta = JSON.parse(split.split_meta);
    meta[division] = checkedValue;

    if (checkedValue !== 'none') {
      meta[`${division}-type`] = type;
    } else {
      delete meta[`${division}-type`];
    }

    split.split_meta = JSON.stringify(meta);
    split.updated = true;
    const newSplits = [...withoutId(props.model.splits, split.id), split];
    props.setSplitsState(newSplits);
  }

  function renderXeroAccountSelector(division) {

    let reapBucketId = null;
    if (props.row.row_type === 'reap-account' ) {
      reapBucketId = props.row.id;
    } else if (props.row.row_type === 'pal') {      
      reapBucketId = props.row.reap_account_id;
    }
    
    const bucketOptions = props.model.reapBuckets.filter(rb => rb.id !== reapBucketId).map(rb => {
      return <Option key={rb.id} value={`reap-${rb.id}`} label={rb.name}>REAP - {rb.name}</Option>
    });

    const xeroAccountOptions = props.model.xeroAccounts.map(xeroAccount => {
      return <Option key={xeroAccount.id} value={`xero-${xeroAccount.id}`} label={xeroAccount.name}>XERO - {xeroAccount.name}</Option>
    });

    const options = bucketOptions.concat(xeroAccountOptions);
    const type = splitMeta[`${division}-type`]
    const defaultValue = type == null ? 'none' : `${type}-${splitMeta[division]}`
    return (      
      <Select
        showSearch
        style={{ width: 250 }}
        defaultValue={defaultValue}
        onChange={(value) => {
          const type = value.startsWith("xero") ? 'xero' : 'reap';
          const valueId = value.substring(5);
          onMappingChanged(division, valueId, type)}
        }
        optionFilterProp="children"
        filterOption={(input, option) => {
          return option.label.toLowerCase().includes(input.toLowerCase());
        }}>          
        <Option key="none" value="none" label="None">None</Option>
        {options}
      </Select>
    );
  }

  function getRowValue(division) {
    const id = splitMeta[division];
    const type = splitMeta[`${division}-type`];
    if (id === 'none') {
      return 0;
    }
    if (type === 'xero') {    
      const pal = props.model.xeroAccounts.find(a => a.id === id);
      return pal.amount;
    }
    else if (type === 'reap') {
      const rb = props.model.reapBucketSums.find(a => a.id === id);
      return rb.amount;
    }
  }

  function renderSelectedAccountValue(division) {
    const value = getRowValue(division);
    return (<p>{value.toFixed(2)}</p>);
  }

  function renderAccountRatio(division) {
    const ratios = props.model.getAccountBasedProRataRatio(splitMeta);
    const ratio = ratios[division];

    return ratio.toFixed(3);
  }

  function ok() {
    props.setAccountProRataVisible(false);
    props.setMenuVisible(true);
  }

  function back() {
    props.setMenuVisible(true);
    props.setAccountProRataVisible(false);
  }

  return (
    <div>
      <div style={{ height: 180 }}>
        <Row>
          <Col span={8}><strong>Sales</strong></Col>
          <Col span={8}><strong>PM</strong></Col>
          <Col span={8}><strong>Dir</strong></Col>
        </Row>
        <Row style={{ marginTop: 10, marginLeft: -5 }}>
          <Col span={8}>{renderXeroAccountSelector('sales')}</Col>
          <Col span={8}>{renderXeroAccountSelector('pm')}</Col>
          <Col span={8}>{renderXeroAccountSelector('dir')}</Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={8}>{renderSelectedAccountValue('sales')}</Col>
          <Col span={8}>{renderSelectedAccountValue('pm')}</Col>
          <Col span={8}>{renderSelectedAccountValue('dir')}</Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={8}><strong>{renderAccountRatio('sales')}</strong></Col>
          <Col span={8}><strong>{renderAccountRatio('pm')}</strong></Col>
          <Col span={8}><strong>{renderAccountRatio('dir')}</strong></Col>
        </Row>
      </div>
      <Button type="default"
        style={{
          marginTop: '20px',
          float: 'right',
          marginRight: '10px'
        }}
        onClick={back}>Back</Button>
      <Button type="primary"
        style={{
          marginTop: '20px',
          float: 'right',
          marginRight: '10px'
        }}
        onClick={ok}>OK</Button>
    </div>
  );
}

export default AccountBasedProRataSplitSubMenu;
