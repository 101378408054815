import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag, Row, Col, Tooltip, Button } from 'antd';
import { action,
         GET_TENANTS,
         XERO_PALS_GET,
         REAP_GET_ACCOUNTS,
         XERO_REAP_MAPPING_GET,
         XERO_GET_ACCOUNTS,
         XERO_PULL_GET,
         XERO_PALS_UPDATE_CHILDREN
       } from '../../state/actions'
import { ROUTE_REAP_PROFIT_AND_LOSS } from '../../AppRoutes.js' ;
import './pal.css';
import { SaveOutlined } from '@ant-design/icons';
import PalValue from './PalValue.js';
import XeroProfitAndLossMenu from './XeroProfitAndLossMenu';

function XeroProfitAndLoss() {
  const { Title, Text } = Typography;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const pullId = searchParams.get("pull_id");
  const officeId = searchParams.get("office_id");

  const [childAdjustments, setChildAdjustments] = useState([]);
  const [lineActions, setLineActions] = useState([]);

  const pals = useSelector(state => state.xero.accountPals)
        .filter(p => p.pull_id === pullId);
  const tenants = useSelector(state => state.xero.tenants)
        .filter(t => t.office_id === officeId);
  const reapAccounts = useSelector(state => state.reap.accounts);
  const mappings = useSelector(state => state.reap.mappings)
        .filter(m => m.office_id === officeId);
  const xeroAccounts = useSelector(state => state.xero.accounts)
        .filter(t => t.office_id === officeId);
  const pull = useSelector(state => state.xero.accountPulls)
        .find(p => p.id === pullId);

  useEffect(() => {
    dispatch(action(XERO_PALS_GET, { pullId }));
    dispatch(action(GET_TENANTS, { officeId }));
    dispatch(action(REAP_GET_ACCOUNTS));
    dispatch(action(XERO_REAP_MAPPING_GET, officeId));
    dispatch(action(XERO_GET_ACCOUNTS, officeId));
    dispatch(action(XERO_PULL_GET, { id: pullId }));
  }, [dispatch, pullId, officeId]);

  if (pals.length === 0         ||
      tenants.length === 0      ||
      reapAccounts.length === 0 ||
      mappings.length === 0     ||
      xeroAccounts.length === 0 ||
      pull === undefined ) {
    return null;
  }

  function onUpdateChildAccounts(parentId) {
    const children = childAdjustments.filter(c => c.parent === parentId)
          .map(c => { return { id: c.id, amount: c.amount }});

    dispatch(action(XERO_PALS_UPDATE_CHILDREN, children));

    setLineActions(lineActions.filter(la => !(la.id === parentId,
                                              la.action === 'save')));

  }

  function lineIs(id, action) {
    return lineActions.some(a => a.id === id && a.action === action);
  }

  const columns = [
    {
      title: '',
      width: 20,
      elipsis: true,
      className: 'fixed-width-cell',
      render: (_, record) => {
        if (lineIs(record.id, 'save')) {
          return (
            <Tooltip title="Save Child Acconts">
              <Button shape="circle" size="small"
                onClick={() => { onUpdateChildAccounts(record.id); }}
                icon={<SaveOutlined style={{ color: 'green' }} />} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Options">
              <XeroProfitAndLossMenu record={record}/>
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Account Code',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 50,
      render: (account_id, record) => {
        if (record.child) {
          return null;
        }

        const xeroAccount = xeroAccounts.find(xa => xa.account_id === account_id);
        return xeroAccount.code;
      }
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'name',
      width: 300
    },
    {
      title: 'Value',
      dataIndex: 'amount',
      key: 'amount',
      width: 50,
      align: 'right',
      render: (number, record) => {
        return <PalValue number={number}
                         record={record}
                         pals={pals}
                         childAdjustments={childAdjustments}
                         setChildAdjustments={setChildAdjustments}
                         lineActions={lineActions}
                         setLineActions={setLineActions}/>
      }
    },
    {
      title: 'REAP Account',
      dataIndex: 'account_id',
      key: 'account_id',
      width: 200,
      render: (account_id) => {
        const xeroAccount = xeroAccounts.find(xa => xa.account_id === account_id);
        const mapping = mappings.find(m => m.xero_account_id === xeroAccount.id);
        const reapAccount = reapAccounts.find(ra => ra.id === mapping.reap_account_id);
        return (
          <Tag color='blue'>{reapAccount.name}</Tag>
        );
      }
    }
  ];

  function renderTable(pals, header) {
    return (
      <div style={{marginTop: 60}} key={header}>
        <Table rowKey='id'
               dataSource={pals}
               columns={columns}
               size="small"
               pagination={false}
               bordered
               title={() => { return (<Title level={5}>{header}</Title>) }} />
      </div>
    );
  }

  function renderPalsBySection(pals) {
    const palsBySection = pals.reduce((acc, item) => {
      const key = item.section_title;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const tables = [];

    for (const key in palsBySection) {
      tables.push(renderTable(palsBySection[key], key));
    }

    return tables;
  };

  function renderPalsByTenant() {
    const palsByTenant = pals.reduce((acc, item) => {
      const key = item.tenant_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const tenant = tenants.find(t => t.tenant_id === t.tenant_id);
    const heading = (
      <Title level={5} key={tenant.id}>{tenant.tenant_name}</Title>
    );

    const tables = [];
    tables.push(heading);
    for (const key in palsByTenant) {
      const items = palsByTenant[key];
      tables.push(renderPalsBySection(items));
    }

    return tables;
  }

  function onClickReapProfitAndLoss() {
    const params = {
      pull_id: pullId,
      office_id: officeId
    };

    navigate({
      pathname: ROUTE_REAP_PROFIT_AND_LOSS,
      search: createSearchParams(params).toString()
    });
  }

  const fromDate = (new Date(pull.from_date)).toLocaleDateString();
  const toDate = (new Date(pull.to_date)).toLocaleDateString()
  const reportDate = (new Date(pull.report_date)).toLocaleDateString()

  return (
    <div>
      <Title level={3}>Xero Profit And Loss</Title>
      <Text type="secondary">From API call on {reportDate}, with date ranges from {fromDate} to {toDate}.</Text>
      <div style={{marginTop: '20px'}}>
        <Button type="primary" onClick={onClickReapProfitAndLoss}>REAP Profit and Loss View</Button>
      </div>
      <Row>
        <Col span={24}>
          { renderPalsByTenant() }
        </Col>
      </Row>

    </div>
  );
}

export default XeroProfitAndLoss;
