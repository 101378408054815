import React from 'react';
import { Modal, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { action, ACC_SPLIT_TEMPLATE_CREATE } from '../../state/actions';
import { useSearchParams } from 'react-router-dom';

function SaveTemplateModal(props) {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const [form] = Form.useForm();

  const pullId = searchParams.get("pull_id");
  const officeId = searchParams.get("office_id");

  async function onOk() {
    const values = await form.validateFields();
    props.setSaveTemplateVisible(false);
    form.resetFields();

    Object.assign(values, {
      officeId,
      pullId
    });

    dispatch(action(ACC_SPLIT_TEMPLATE_CREATE, values));
  }

  function onCancel() {
    props.setSaveTemplateVisible(false);
  }

  return (
    <Modal title="Save Split Template"
           open={props.saveTemplateVisible}
           onOk={onOk}
           onCancel={onCancel}>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onOk}
        autoComplete="off"
      >
        <Form.Item
          label="Template Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please provide a name for the template.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SaveTemplateModal;
