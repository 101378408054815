const initialState = {
  accessControl: {
    jwt: '',
    authState: '',
    invalidPasswordState: false
  },
  xero: {
    authState: '',
    authUrl: '',
    connections: [],
    tenants: [],
    accounts: [],
    accountPulls: [],
    serverPullCount: 0,
    accountPals: [],
    accPalAdjustments: []
  },
  reap: {
    accounts: [],
    mappings: [],
    mappingCompletions: []
  },
  offices: {
    items: [],
    serverCount: 0,
    accountStatus: []
  },
  tokens: {
    tokenExists: []
  },
  accountingThreads: {
    currentStep: []
  },
  accounting: {
    joinedPals: [],
    splits: [],
    proRataSplits: [],
    splitTemplates: [],
    splitTemplateLines: []
  },
  reporting: {
    financialYears: [],
    financialYearHasData: []
  },
  system: {
    backgroundTasks: [],
    backgroundTaskCount: 0
  },
  signal: null
};

export default initialState;
