import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Tooltip, Button,
         Typography, Form, Modal, Input } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { action,
         GET_TENANTS,
         XERO_PALS_GET,
         REAP_GET_ACCOUNTS,
         XERO_REAP_MAPPING_GET,
         XERO_GET_ACCOUNTS,
         XERO_PULL_GET,
         ACC_PAL_ADJUSTMENT_ADD,
         ACC_PAL_ADJUSTMENTS_GET,
         ACC_PAL_ADJUSTMENT_REMOVE
       } from '../../state/actions';
import { PlusOutlined, MinusOutlined, CloudDownloadOutlined, InfoOutlined } from '@ant-design/icons';
import axios from 'axios';
import { userIs } from '../../utils/jwt.js';
import { ROLE_USER } from '../../const.js';
import { copy } from '../../utils/data.js';

function ReapProfitAndLoss() {
  const { Title, Text } = Typography;

  const jwt = useSelector(state => state.accessControl.jwt);
  const readOnly = userIs(jwt, ROLE_USER);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const pullId = searchParams.get("pull_id");
  const officeId = searchParams.get("office_id");

  const pals = useSelector(state => state.xero.accountPals)
        .filter(p => p.pull_id === pullId);
  const tenants = useSelector(state => state.xero.tenants)
        .filter(t => t.office_id === officeId);
  const reapAccounts = copy(useSelector(state => state.reap.accounts))
        .sort((a, b) => a.order - b.order);
  const mappings = useSelector(state => state.reap.mappings)
        .filter(m => m.office_id === officeId);
  const xeroAccounts = useSelector(state => state.xero.accounts)
        .filter(t => t.office_id === officeId);
  const pull = useSelector(state => state.xero.accountPulls)
        .find(p => p.id === pullId);
  const adjustments = useSelector(state => state.xero.accPalAdjustments)
        .filter(a => a.pull_id === pullId);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalReapAccount, setModalReapAccount] = useState(false);

  useEffect(() => {
    dispatch(action(XERO_PALS_GET, { pullId }));
    dispatch(action(GET_TENANTS, { officeId }));
    dispatch(action(REAP_GET_ACCOUNTS));
    dispatch(action(XERO_REAP_MAPPING_GET, officeId));
    dispatch(action(XERO_GET_ACCOUNTS, officeId));
    dispatch(action(XERO_PULL_GET, { id: pullId }));
    dispatch(action(ACC_PAL_ADJUSTMENTS_GET, pullId));
  }, [dispatch, pullId, officeId]);

  if (pals.length === 0         ||
      tenants.length === 0      ||
      reapAccounts.length === 0 ||
      mappings.length === 0     ||
      xeroAccounts.length === 0 ||
      pull === undefined ) {
    return null;
  }
  const renderModal = () => {
    const onOk = async () => {
      try {
        const values = await form.validateFields();
        setModalVisible(false);
        form.resetFields();
        Object.assign(values, {
          reapAccountId: modalReapAccount,
          pullId
        });

        dispatch(action(ACC_PAL_ADJUSTMENT_ADD, values));
      }
      catch {
      }
    };

    const onCancel = () => {
      setModalVisible(false);
    }

    return (
      <Modal title="Add Adjustment" open={modalVisible} onOk={onOk} onCancel={onCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onOk}
          autoComplete="off"
        >
          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please add a note for the adjustment.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please add adjustment value.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
          </Form.Item>
        </Form>
      </Modal>
    );
  };


  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 40,
      render: (_, record) =>  {
        if (record.hasChildValues) {
          return (
            <Tooltip title="Has child accounts with values.">
              <Button shape="circle" size="small"
                icon={<InfoOutlined style={{ color: 'green' }} />} />
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      align: 'right',
      render: value => (value.toFixed(2))
    },
  ];

  function palsInBucket(reapAccountId) {
    const maps = mappings.filter(m => m.reap_account_id === reapAccountId);
    const mappedXeroAccounts = xeroAccounts.filter(x => maps.some(m => m.xero_account_id === x.id));
    const palsInBucket = pals.filter(p => mappedXeroAccounts.some(x => x.account_id === p.account_id))
          .map(p => {
            const xeroAccount = mappedXeroAccounts.find(x => x.account_id === p.account_id);
            const childSum = pals.filter(i => i.parent === p.id)
                  .reduce((a, i) => a + i.amount, 0);
            const newPal = {}
            Object.assign(newPal, p, { code: xeroAccount.code });
            newPal.amount = newPal.amount - childSum;
            if (childSum > 0) {
              newPal.hasChildValues = true;
            }
            return newPal;
          });
    return palsInBucket;
  }

  function renderPalTotalRow(reapAccountId, pals, adjustments) {
    const total = pals.reduce((acc, p) => acc + p.amount, 0.0) +
                  adjustments.reduce((acc, a) => acc + a.amount, 0.0);
    return (
      <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}  key={`${reapAccountId}-totals`}>
        <Table.Summary.Cell><strong>Total</strong></Table.Summary.Cell>
        <Table.Summary.Cell>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right">
          <Text><strong>{total.toFixed(2)}</strong></Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  function renderActionsRow(reapAccountId) {
    return (
      <Table.Summary.Row style={{ backgroundColor: '#fafafa' }} key={`${reapAccountId}-actions`}>
        <Table.Summary.Cell colSpan={4} style={{margin: '20px'}}>
          <Text><strong>Adjustments</strong></Text>
          <div style={{ float: 'right', marginRight: 10 }}>
            {!readOnly &&
              <Tooltip title="Add Adjustment">
                <Button shape="circle" size="small"
                  onClick={() => {
                    setModalReapAccount(reapAccountId);
                    setModalVisible(true);
                  }}
                  icon={<PlusOutlined style={{ color: 'green' }} />} />
              </Tooltip>
            }
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  function deleteAdjustment(id) {
    dispatch(action(ACC_PAL_ADJUSTMENT_REMOVE, id));
  }

  function renderAdjustmentRows(palAdjustments) {
    const result = palAdjustments.map(a => {
      return (
        <Table.Summary.Row key={a.id}>
          <Table.Summary.Cell>
            { !readOnly &&
              <Tooltip title="Delete Adjustment">
                <Button shape="circle" size="small"
                        onClick={() => { deleteAdjustment(a.id); }}
                        icon={<MinusOutlined style={{ color: 'red' }} />} />
              </Tooltip>
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text>{a.note}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <Text>{a.amount.toFixed(2)}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    });

    return result;
  }

  function renderSummaryRows(pals, reapAccountId) {
    const reapAccountAdjustments = adjustments.filter(a => a.reap_account_id === reapAccountId);
    const items = [];
    items.push(renderActionsRow(reapAccountId));
    items.push(...renderAdjustmentRows(reapAccountAdjustments));
    items.push(renderPalTotalRow(reapAccountId, pals, reapAccountAdjustments));
    return items;
  }

  function renderPals(pals, header, reapAccountId) {
    return (
      <div style={{ marginTop: 60 }} key={reapAccountId}>
        <Table
          key={reapAccountId}
          rowKey="id"
          dataSource={pals}
          columns={columns}
          size="small"
          pagination={false}
          bordered
          title={() => { return (<Title level={5}>{header}</Title>) }}
          summary={() => renderSummaryRows(pals, reapAccountId)}
        />

      </div>
    );
  }

  function renderPalsByReapBucket() {
    const items = [];
    for (const r of reapAccounts) {
      const pb = palsInBucket(r.id);      
      items.push(renderPals(pb, r.name, r.id));

    }
    return items;
  };

  const fromDate = (new Date(pull.from_date)).toLocaleDateString();
  const toDate = (new Date(pull.to_date)).toLocaleDateString()
  const reportDate = (new Date(pull.report_date)).toLocaleDateString()

  function downloadCSV() {
    axios({
      url: `/accounting/get-reap-pal-download-link?pullId=${pullId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${pullId}.csv`);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  return (
    <div>
      <Title level={3}>REAP Profit And Loss</Title>
      <Text type="secondary">From API call on {reportDate}, with date ranges from {fromDate} to {toDate}.</Text>
      {renderModal()}
      <Tooltip title="Download CSV File">
        <Button shape="circle" size="small"
                style={{marginLeft: '20px'}}
          onClick={downloadCSV}
          icon={<CloudDownloadOutlined style={{ color: 'blue' }} />} />
      </Tooltip>
      <Row>
        <Col span={24}>
          {renderPalsByReapBucket()}
        </Col>
      </Row>
    </div>
  );
}

export default ReapProfitAndLoss;
