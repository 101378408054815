import React from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';

const PermissionError = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column'
    }}>
      <div style={{ marginTop: '200px' }}>
        <QuestionCircleOutlined style={{ fontSize: '3em' }} />
      </div>
      <div>
        <p>The item your looking for cannot be found.</p>
      </div>
    </div>

  );
};

export default PermissionError;
