const logger = () => {
  const self = {};

  const logLine = (level, text, tags) => {
    const timestamp = new Date().toISOString();
    if (tags) {
      return `${timestamp} ${level} ${text} [${tags}]`;
    }

    return `${timestamp} ${level} ${text}`;
  };

  self.error = (text, tags='') => {
    const log = logLine('error', text, tags);
    console.error(log);
  };

  return self;
};

const log = logger();

export default log;
