import React from 'react';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Tooltip, Button } from 'antd';
import LoginMicrosoft from '../AccessControl/LoginMirosoft.js';
import LoginReap from '../AccessControl/LoginReap.js';
import LoginXero from '../AccessControl/LoginXero.js';
import { useNavigate } from 'react-router-dom';
import { ROUTE_REGISTER } from '../../AppRoutes.js';

const Login = () => {
  const navigate = useNavigate()

  const onRegister = () => {
    navigate(ROUTE_REGISTER);
  };

  return (
    <div>
      <Card
        style={{ width: 480, height: 440, marginTop: 180 }}
        cover={
          <img
            alt="Splash Screen"
            src={require('./login-splash.png')}
          />
        }
        actions={[
          <Tooltip title="Create New Account">
            <Button onClick={onRegister} type="dashed" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>,
          <EllipsisOutlined key="ellipsis" />,
        ]}>

        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          <LoginReap />
          <LoginMicrosoft />
          <LoginXero />
        </div>
      </Card>
    </div>
  );
};

export default Login;
