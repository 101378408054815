import axios from 'axios';
import { call, put, takeLatest } from "redux-saga/effects";

import {
  REPORT_FINANCIAL_YEARS_GET,
  REPORT_FINANCIAL_YEARS_STORE,
  REPORT_FIN_YEAR_PALS_GET,
  ACC_PAL_JOINED_STORE,
  XERO_PULLS_STORE,
  ACC_REAP_DIVISIONAL_SPLITS_STORE,
  ACC_PAL_GET_PRO_RATA_SPLITS_STORE,
  ACC_PAL_ADJUSTMENT_STORE,
  REAP_PUT_ACCOUNTS,
  REPORT_FIN_YEAR_HAS_DATA_GET,
  REPORT_FIN_YEAR_HAS_DATA_STORE,
  action
} from './actions.js';

const newAction = action;

function* getReportFinancialYearsSaga(action) {
  const getReportFinancialYears = async(params) => {
    const url = `/reporting/get-financial-years`;
    const res = await axios.get(url, { params });
    return res.data;
  };

  const years = yield call(getReportFinancialYears, action.payload);
  yield put(newAction(REPORT_FINANCIAL_YEARS_STORE, years));
};

function* getReportFinancialYearPalsSaga(action) {
  const getReportFinancialYearPals = async(params) => {
    const url = `/reporting/get-financial-year-pals`;
    const res = await axios.get(url, { params });
    return res.data;
  };

  const result = yield call(getReportFinancialYearPals, action.payload);
  yield put(newAction(XERO_PULLS_STORE, result.pulls));
  yield put(newAction(ACC_PAL_JOINED_STORE, result.pals));
  yield put(newAction(ACC_REAP_DIVISIONAL_SPLITS_STORE, result.splits));
  yield put(newAction(ACC_PAL_GET_PRO_RATA_SPLITS_STORE, result.proRataSplits));
  yield put(newAction(ACC_PAL_ADJUSTMENT_STORE, result.palAdjustments));
  yield put(newAction(REAP_PUT_ACCOUNTS, result.reapAccounts));
};

function* getReportFinancialYearHasDataGetSaga(action) {
  const getReportFinancialYearHasDataGet = async(params) => {
    const url = `/reporting/get-financial-year-has-data`;
    const res = await axios.get(url, { params });
    return res.data;
  };

  const result = yield call(getReportFinancialYearHasDataGet, action.payload);
  yield put(newAction(REPORT_FIN_YEAR_HAS_DATA_STORE, result));
};


export default function* reportingSagas() {
  yield takeLatest(REPORT_FINANCIAL_YEARS_GET, getReportFinancialYearsSaga);
  yield takeLatest(REPORT_FIN_YEAR_PALS_GET, getReportFinancialYearPalsSaga);
  yield takeLatest(REPORT_FIN_YEAR_HAS_DATA_GET, getReportFinancialYearHasDataGetSaga);
}
