import React from 'react';
import './AppUnauthenticated.css';

const AppUnAuthenticated = (props) => {
  return (
    <div className='app-unauthenticated'>
      {props.children}
    </div>
  );
}

export default AppUnAuthenticated;
