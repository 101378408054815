import React from "react";
import AppAuthenticated from '../AppAuthenticated/AppAuthenticated.js';
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { ROLE_USER } from '../../const.js';
import UserHome from './UserHome.js';
import { jwtValid } from '../../utils/jwt.js';

const Home = () => {
  const jwt = useSelector(state => state.accessControl.jwt);

  if (!jwtValid(jwt)) {
    return null;
  }

  var jwtDecoded = jwt_decode(jwt);

  if (jwtDecoded.role === ROLE_USER) {
    return  <UserHome/>
  };
};

export default Home;
