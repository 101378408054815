import AddOffice from './features/Office/AddOffice.js';
import App from './App.js';
import AuthorizeMicrosoft from "./features/AccessControl/AuthorizeMicrosoft.js";
import AuthorizeReap from './features/AccessControl/AuthorizeReap.js';
import Home from './features/Home/Home.js';
import Login from './features/Login/Login.js';
import OverviewOffice from './features/Office/OverviewOffice.js';
import React from "react";
import Register from './features/Register/Register.js';
import Settings from './features/Settings/Settings.js';
import ViewOffice from './features/Office/ViewOffice.js';
import AuthorizeXero from './features/Integration/AuthorizeXero.js';
import StartAccounting from './features/Office/StartAccounting.js';
import { createBrowserRouter } from "react-router-dom";
import MapAccounts from './features/Accounting/MapAccounts.js';
import ScheduleAccountingPeriod from './features/Accounting/ScheduleAccountingPeriod.js';
import XeroProfitAndLoss from './features/Accounting/XeroProfitAndLoss.js';
import ReapProfitAndLoss from './features/Accounting/ReapProfitAndLoss.js';
import DivisionalProfitAndLoss from './features/Accounting/DivisionalProfitAndLoss.js';
import FinancialYearReportView from './features/Reports/FinancialYearReportView.js';
import FinancialYearReportPrint from './features/Reports/FinancialYearReportPrint.js';
import BackgroundTasks from './features/BackgroundTasks/BackgroundTasks.js';

export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTER = '/register';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_ACCESS_CONTROL_MICROSOFT = '/access-control/authorize-microsoft';
export const ROUTE_ACCESS_CONTROL_REAP = '/access-control/reap';
export const ROUTE_OFFICE_ADD = '/office/add';
export const ROUTE_OFFICE_VIEW = '/office/view';
export const ROUTE_OFFICE_OVERVIEW = '/office/overview';
export const ROUTE_OFFICE_START_ACCOUNTS = '/office/start-accounting';
export const ROUTE_INT_XERO_REDIRECT = '/integration/authorize-xero';
export const ROUTE_ACC_MAP = '/accounts/map';
export const ROUTE_REAP_PROFIT_AND_LOSS = '/accounts/reap-profit-and-loss';
export const ROUTE_REPORT_FIN_YEAR_PRINTABLE = '/reports/financial-year-print';

const router = createBrowserRouter([{
  element: <App />,
  children: [
    {
      path: '/',
      element: <Home/>
    },
    {
      path: 'office',
      children: [
        {
          path: 'add',
          element: (
            <AddOffice/>
          ),
        },
        {
          path: 'view',
          element: <ViewOffice/>,
        },
        {
          path: 'overview',
          element: <OverviewOffice/>
        },
        {
          path: 'start-accounting',
          element: <StartAccounting />
        }
      ],
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'map',
          element: (
            <MapAccounts/>
          )
        },
        {
          path: 'schedule',
          element: (
            <ScheduleAccountingPeriod/>
          )
        },
        {
          path: 'xero-profit-and-loss',
          element: (
            <XeroProfitAndLoss/>
          )
        },
        {
          path: 'reap-profit-and-loss',
          element: (
            <ReapProfitAndLoss />
          )
        },
        {
          path: 'divisional-profit-and-loss',
          element: (
            <DivisionalProfitAndLoss />
          )
        }
      ]
    },
    {
      path: 'reports',
      children: [
        {
          path: 'financial-year',
          element: (
            <FinancialYearReportView/>
          )
        },
        {
          path: 'financial-year-print',
          element: (
            <FinancialYearReportPrint/>
          )
        },
      ]
    },
    {
      path: 'login',
      element: <Login/>
    },
    {
      path: 'register',
      element: <Register/>
    },
    {
      path: 'access-control',
      children: [
        {
          path: 'reap',
          element: <AuthorizeReap/>
        },
        {
          path: 'authorize-microsoft',
          element: <AuthorizeMicrosoft/>
        }
      ]
    },
    {
      path: 'settings',
      element: <Settings/>
    },
    {
      path: 'background-tasks',
      children: [
        {
          path: 'overview',
          element: <BackgroundTasks/>
        },
      ]
    },
    {
      path: 'integration',
      children: [
        {
          path: 'authorize-xero',
          element: <AuthorizeXero/>
        }
      ]
    }
  ],
}]);

export { router }
