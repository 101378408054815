import React from 'react';
import './AppIntegrations.css';

const AppIntegrations = (props) => {
  return (
    <div className='app-integrations'>
      {props.children}
    </div>
  );
}

export default AppIntegrations;
