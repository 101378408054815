import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';

import {
  action,
  CHECK_TOKEN_EXISTS,
  STORE_TOKEN_EXISTS,
  DELETE_TOKEN,
  UNSTORE_TOKEN_EXISTS,
} from './actions.js';

const newAction = action;

function* getTokenExistsSaga(action) {
  const getTokenExists = async (payload) => {
    const params = new URLSearchParams(payload).toString();
    const url = `/integration/token/exists-by-user-office?${params}`;
    const res = await axios.get(url);
    const response = res.data;
    response.tokenId = response.id;

    // Some tokens may be non existant, so no id field (null)
    response.id = `${response.userId}${response.officeId}${response.application}`;
    return response;
  };

  const response = yield call(getTokenExists, action.payload);
  yield put(newAction(STORE_TOKEN_EXISTS, [response]));
}

function* deleteTokenSaga(action) {
  const deleteToken = async (payload) => {
    const params = new URLSearchParams(payload).toString();
    const url = `/integration/token/revoke?${params}`;
    const res = await axios.delete(url);
    return res.data;
  };

  yield call(deleteToken, action.payload);
  yield put(newAction(UNSTORE_TOKEN_EXISTS, action.payload.id));
  const actionCheckTokenExists = newAction(CHECK_TOKEN_EXISTS, {
    officeId: action.payload.officeId,
    application: action.payload.application
  });

  yield call(getTokenExistsSaga, actionCheckTokenExists);
};

export default function* integrationSagas() {
  yield takeLatest(CHECK_TOKEN_EXISTS, getTokenExistsSaga);
  yield takeLatest(DELETE_TOKEN, deleteTokenSaga);
}
