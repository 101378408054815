import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card,
         Button,
         Form,
         Input,
         notification } from 'antd';
import log from '../../utils/log.js';
import { useNavigate } from 'react-router-dom';
import { action,
         AUTHORIZE_REAP,
         WRITE_INVALID_PASSWORD_STATE } from '../../state/actions.js';
import { ROUTE_LOGIN, ROUTE_HOME } from '../../AppRoutes.js';
import { jwtValid } from "../../utils/jwt.js";

const AuthorizeReap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jwt = useSelector(state => state.accessControl.jwt);
  const invalidPassword = useSelector(state => state.accessControl.invalidPasswordState);

  const [api, contextHolder] = notification.useNotification();
  const showLoginFailedNotfication = () => {
    api.info({
      message: `Login Failed`,
      description:
        'The username and password pair that you entered is not valid.',
      placement: 'top',
    });
  }

  useEffect(() => {
    if (jwtValid(jwt)) {
      navigate(ROUTE_HOME);
    }
  }, [jwt, navigate]);

  useEffect(() => {
    if (invalidPassword) {
      showLoginFailedNotfication();
      dispatch(action(WRITE_INVALID_PASSWORD_STATE, false));
    }
  }, [invalidPassword]);

  const onFinish = (values) => {
    dispatch(action(AUTHORIZE_REAP, values));
  };
  const onFinishFailed = (errorInfo) => {
    log.error(JSON.stringify(errorInfo));
  };
  const onBack = () => {
    navigate(ROUTE_LOGIN);
  };

  return (
    <div>
      {contextHolder}
      <Card
        style={{ width: 680, height: 440, marginTop: 180 }}
        actions={[
        ]}>
        <div style={{ display: 'flex' , justifyContent: 'center', marginTop: 40}}>
          <Form
            name="authorize-reap"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
            style={{
              maxWidth: 680,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'Should be a valid email address.',
                },
                {
                  required: true,
                  message: 'Please add your email address.',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'A password is required.',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>


            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 18,
              }}
              style={{marginTop: 60}}
            >
              <Button type="default" onClick={onBack} style={{width: '45%', marginRight: '10%'}}>
                Back
              </Button>
              <Button type="primary" htmlType="submit" style={{width: '45%'}}>
                Login
              </Button>
            </Form.Item>
          </Form>

        </div>
      </Card>
    </div>
  );
};

export default AuthorizeReap;
