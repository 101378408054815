import React from 'react';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ACCESS_CONTROL_REAP } from '../../AppRoutes.js';

const LoginReap = () => {
  const navigate = useNavigate();

  const onLoginReap = () => {
    navigate(ROUTE_ACCESS_CONTROL_REAP);
  };

  return (
    <Tooltip title="Sign In with REAP Account.">
      <a target="_self" onClick={onLoginReap}>
        <img alt="Sign In with REAP Account." src={require('./login-reap.png')}
          style={{ width: '48px', height: '48px' }} />
      </a>
    </Tooltip>
  );
};

export default LoginReap;
