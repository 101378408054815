import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import {
  action,
  ACCOUNTING_THREAD_CURRENT_GET,
  XERO_PULLS_GET
} from '../../state/actions.js';
import { Row, Col } from 'antd';
import { ROLE_USER } from '../../const.js';
import { userIs } from '../../utils/jwt.js';
import { Table } from 'antd';
import { Link } from "react-router-dom";
import { Typography } from 'antd';
import { copy } from '../../utils/data.js';

const { Text } = Typography;

function ViewOfficeAdmin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Title } = Typography;

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(action(ACCOUNTING_THREAD_CURRENT_GET, props.officeId));
  }, [dispatch]);

  useEffect(() => {
    dispatch(action(XERO_PULLS_GET, { office_id: props.officeId, page: page }));
  }, [dispatch, page]);

  const jwt = useSelector(state => state.accessControl.jwt);
  const currentStep = useSelector(
    state => state.accountingThreads.currentStep
  ).find(cs => cs.office_id === props.officeId);
  const accountPulls = useSelector(state => state.xero.accountPulls)
    .filter(p => p.office_id === props.officeId);
  const serverPullCount = useSelector(state => state.xero.serverPullCount);

  if (!currentStep) {
    return null;
  }

  const accountPullColumns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      render: (month) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
        return monthNames[month];
      }
    },
    {
      title: 'Report Date',
      dataIndex: 'report_date',
      key: 'report_date',
      render: (dateZulu) => (new Date(dateZulu)).toLocaleDateString()
    },
    {
      title: 'Start Date',
      dataIndex: 'from_date',
      key: 'from_date',
      render: (dateZulu) => (new Date(dateZulu)).toLocaleDateString()
    },
    {
      title: 'End Date',
      dataIndex: 'to_date',
      key: 'to_date',
      render: (dateZulu) => (new Date(dateZulu)).toLocaleDateString()
    },
    {
      title: 'XERO P&L',
      dataIndex: 'to_date',
      key: 'to_date',
      render: (_, row) => {
        const url = `/accounts/xero-profit-and-loss?pull_id=${row.id}&office_id=${props.officeId}`
        const display = 'View XERO P&L';
        return (
          <Link to={url}>{display}</Link>
        );
      }
    },
    {
      title: 'REAP P&L',
      dataIndex: 'to_date',
      key: 'to_date',
      render: (_, row) => {
        const url = `/accounts/reap-profit-and-loss?pull_id=${row.id}&office_id=${props.officeId}`
        const display = 'View REAP P&L';
        return (
          <Link to={url}>{display}</Link>
        );
      }
    },
    {
      title: 'Divisional REAP P&L',
      dataIndex: 'to_date',
      key: 'to_date',
      render: (_, row) => {
        const url = `/accounts/divisional-profit-and-loss?pull_id=${row.id}&office_id=${props.officeId}`
        const display = 'View Divisional P&L';
        return (
          <Link to={url}>{display}</Link>
        );
      }
    },
  ];

  const onMap = () => {
    const url = `/accounts/map?officeId=${props.officeId}`
    navigate(url);
  };

  const renderMapStep = () => {
    if (currentStep.step === 'map') {
      return (
        <Row style={{ marginTop: '40px' }}>
          <Col span={12}>
            <Button style={{ marginTop: '40px' }} type="primary"
              onClick={onMap}>Map XERO Accounts.</Button>
          </Col>
        </Row>
      );
    }
    else if (currentStep.step === 'mapped') {
      return (
      <Row style={{ marginTop: '40px' }}>
        <Col span={12}>
          <Button style={{ marginTop: '40px' }} type="primary"
            onClick={onMap}>Change XERO Account Mappings.</Button>
        </Col>
      </Row>
      );
    }
    else if (currentStep.step === 'remap') {
      return (
      <Row style={{ marginTop: '40px' }}>
        <Col span={12}>
          <div>
            <Text type="warning">New Accounts in XERO detected.</Text>
          </div>
          <Button style={{ marginTop: '40px' }} type="primary"
            onClick={onMap}>Update Account Mappings.</Button>
        </Col>
      </Row>
      );
    }
  };

  const onSchedule = () => {
    const url = `/accounts/schedule?officeId=${props.officeId}`
    navigate(url);
  };

  const onFinancialYearReport = () => {
    const url = `/reports/financial-year?officeId=${props.officeId}`
    navigate(url);
  }

  const renderImportAccountsStep = () => {
    if (userIs(jwt, ROLE_USER)) {
      return null;
    }

    if (currentStep.step === 'mapped') {
      return (
        <Row style={{ marginTop: '40px' }}>
          <Col span={12}>
            <Button style={{ marginTop: '40px' }} type="primary"
              onClick={onSchedule}>Schedule P&L Report</Button>
          </Col>
        </Row>
      );

    }
  };

  const renderOfficeReports = () => {
    return (
      <Row style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Button style={{ marginTop: '20px' }} type="primary"
            onClick={onFinancialYearReport}>Generate Financial Year Report</Button>
        </Col>
      </Row>
    );
  }

  const onPullPagination = (page) => {
    setPage(page - 1);
  };

  const renderPullsList = () => {
    const datasource = copy(accountPulls).sort((a, b) => b.year - a.year ||
                                               b.month - a.month);
    return (
      <Row style={{ marginTop: '40px' }}>
        <Col span={24}>
          <Title level={4}>Profit and Loss Reports</Title>
          <Table rowKey='id'
            dataSource={datasource}
            columns={accountPullColumns}
            size="small"
            pagination={{ 'defaultCurrent': 1, 'total': serverPullCount, 'onChange': onPullPagination }} />
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {renderMapStep()}
      {renderImportAccountsStep()}
      {renderOfficeReports()}
      {renderPullsList()}
    </div>
  );
}

export default ViewOfficeAdmin;
