import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  Button,
  Form,
  Input
} from 'antd';

import log from '../../utils/log.js';
import { useNavigate } from 'react-router-dom';
import { action, REGISTER_NEW_REAP_USER } from '../../state/actions.js';
import { ROUTE_LOGIN } from '../../AppRoutes.js';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const payload = {
      values,
      navigate
    };

    dispatch(action(REGISTER_NEW_REAP_USER, payload));
  };
  const onFinishFailed = (errorInfo) => {
    log.error(JSON.stringify(errorInfo));
  };
  const onBack = () => {
    navigate(ROUTE_LOGIN);
  };

  return (
    <Card
      style={{ width: 680, height: 440, marginTop: 180 }}
      actions={[
      ]}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
        <Form
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          style={{
            maxWidth: 680,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Should be a valid email address.',
              },
              {
                required: true,
                message: 'Please add your email address.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="firstname"
            label="Firstname"
            rules={[{ required: true, message: 'First name is required.', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastname"
            label="Lastname"
            rules={[{ required: true, message: 'Last name is required.', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'A password is required.',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>


          <Form.Item
            name="confirm"
            label="Retype Password:"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please retype your password.',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`The passwords don't match.`));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 10,
              span: 14,
            }}
            style={{ marginTop: 60 }}
          >
            <Button type="default" onClick={onBack} style={{ width: '45%', marginRight: '10%' }}>
              Back
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: '45%' }}>
              Register
            </Button>
          </Form.Item>
        </Form>

      </div>
    </Card>
  );
};

export default Register;
