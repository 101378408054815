import React, { useEffect, useState } from 'react';
import { Typography, Tooltip, Button } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { action,
         XERO_PULL_GET,
         ACC_PAL_JOINED_GET,
         REAP_GET_ACCOUNTS,
        ACC_PAL_ADJUSTMENTS_GET,
         ACC_REAP_DIVISIONAL_SPLITS_GET,
         ACC_PAL_GET_PRO_RATA_SPLIT,
         ACC_SPLIT_TEMPLATES_GET
       } from '../../state/actions.js';
import MonthDivisionalProfitAndLoss from './MonthDivisionalProfitAndLoss';
import { userIs } from '../../utils/jwt.js';
import { ROLE_USER } from '../../const.js';
import { copy } from '../../utils/data.js';
import axios from 'axios';
import { CloudDownloadOutlined } from '@ant-design/icons';
import divisionalProfitAndLossModel from '@lrea/ri-shared/src/divisionalProfitAndLossModel.js';

function DivisionalProfitAndLoss() {
  const { Title, Text } = Typography;

  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const pullId = searchParams.get("pull_id");
  const officeId = searchParams.get("office_id");

  const jwt = useSelector(state => state.accessControl.jwt);
  const pull = useSelector(state => state.xero.accountPulls)
        .find(p => p.id === pullId);
  const joinedPals = useSelector(state => state.accounting.joinedPals)
        .filter(p => p.pull_id === pullId);
  const reapAccounts =  copy(useSelector(state => state.reap.accounts))
        .sort((a, b) => {
          return b.category.localeCompare(a.category) ||
            a.name.localeCompare(a.name);
        });

  const adjustments = useSelector(state => state.xero.accPalAdjustments)
        .filter(a => a.pull_id === pullId);
  const splits = useSelector(state => state.accounting.splits);
  const proRataSplits = useSelector(state => state.accounting.proRataSplits)
        .filter(i => i.id === pullId);
  const splitTemplates = useSelector(state => state.accounting.splitTemplates)
        .filter(i => i.office_id === officeId);

  const readOnly = userIs(jwt, ROLE_USER);

  const [splitsState, setSplitsState] = useState([]);

  useEffect(() => {
    dispatch(action(XERO_PULL_GET, { id: pullId }));
    dispatch(action(REAP_GET_ACCOUNTS));
    dispatch(action(ACC_PAL_JOINED_GET, { pullId, officeId }));
    dispatch(action(ACC_PAL_ADJUSTMENTS_GET, pullId));
    dispatch(action(ACC_REAP_DIVISIONAL_SPLITS_GET, { pullId }));
    dispatch(action(ACC_PAL_GET_PRO_RATA_SPLIT, { officeId, pullId }));
    dispatch(action(ACC_SPLIT_TEMPLATES_GET, { officeId }));
  }, [dispatch, pullId, officeId]);

  useEffect(() => {
    setSplitsState(copy(splits.filter(s => s.pull_id === pullId)));
  }, [splits]);

  if (pull === undefined ||
      reapAccounts.length === 0 ||
      splits.length === 0 ||
      proRataSplits.length === 0) {
    return null;
  }

  const fromDate = (new Date(pull.from_date)).toLocaleDateString();
  const toDate = (new Date(pull.to_date)).toLocaleDateString()

  const reportDate = (new Date(pull.report_date)).toLocaleDateString()
  const model = divisionalProfitAndLossModel(reapAccounts, joinedPals, splitsState, proRataSplits, adjustments);

  if (!model) {
    return null;
  }

  function renderJoinedPals() {
    return <MonthDivisionalProfitAndLoss
             model={model}
             splitTemplates={splitTemplates}
             setSplitsState={setSplitsState}
             fromDate={new Date(pull.from_date)}
             readOnly={readOnly}/>
  }

  function downloadExportExcel() {
    axios({
      url: `/accounting/get-reap-export-download-link?pullId=${pullId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${pullId}.xlsx`);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  return (
    <div>
      <Title level={3}>Divisional REAP Profit And Loss</Title>
      <Text type="secondary">From API call on {reportDate}, with date ranges from {fromDate} to {toDate}.</Text>

      <Tooltip title="Download Excel File">
        <Button shape="circle" size="small"
          style={{ marginLeft: '20px' }}
          onClick={downloadExportExcel}
          icon={<CloudDownloadOutlined style={{ color: 'blue' }} />} />
      </Tooltip>
      {renderJoinedPals()}
    </div>
  );
}

export default DivisionalProfitAndLoss;
