import React, { useEffect, useState } from 'react';
import { Col, Table } from 'antd';
import { action,
         REPORT_FIN_YEAR_PALS_GET,
         REPORT_FIN_YEAR_HAS_DATA_GET
       } from '../../state/actions.js';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FinancialYearReportColumn from './FinancialYearReportColumn.js';
import { copy } from '../../utils/data.js';
import divisionalProfitAndLossModel from '@lrea/ri-shared/src/divisionalProfitAndLossModel.js';
import Loading from '../Loading/Loading.js';

function FinancialYearReport(props) {

const [searchParams, _] = useSearchParams();

  const officeId = searchParams.get("officeId");
  const dispatch = useDispatch();

  const [finYearPulls, setFinYearPulls] = useState([]);
  const [monthColumns, setMonthColumns] = useState([]);

  const accountPulls = useSelector(state => state.xero.accountPulls);

  const reapAccounts = copy(useSelector(state => state.reap.accounts));

  const pals = useSelector(state => state.accounting.joinedPals);

  const splits = copy(useSelector(state => state.accounting.splits)
                      .filter(i => finYearPulls.some(p => p.id === i.pull_id)));

  const adjustments = useSelector(state => state.xero.accPalAdjustments)
        .filter(i => finYearPulls.some(p => p.id === i.pull_id));

  const proRataSplits = useSelector(state => state.accounting.proRataSplits)
        .filter(i => finYearPulls.some(p => p.id === i.id));

  const yearData = useSelector(state => state.reporting.financialYearHasData)
        .filter(i => i.year === props.year);

  function anyEmpty() {
    for (var i = 0; i < arguments.length; i++) {
      if (arguments[i].length === 0) {
        return true;
      }
    }

    return false;
  }

  function stillLoading() {
    if (anyEmpty(accountPulls,
                 reapAccounts,
                 pals,
                 splits,
                 proRataSplits,
                 yearData) && props.year !== null) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    const officeAccountPulls = accountPulls.filter(i => i.office_id === officeId);
    const pulls = [];
    const startYear = props.year - 1;
    const endYear = props.year;

    for (let m = 6; m < 12; m++) {
      const pull = officeAccountPulls.find(i => i.year === startYear && i.month === m);
      if (pull !== undefined) {
        pulls.push(pull);
      }
    }

    for (let m = 0; m < 6; m++) {
      const pull = officeAccountPulls.find(i => i.year === endYear && i.month === m);
      if (pull !== undefined) {
        pulls.push(pull);
      }
    }

    setFinYearPulls(pulls);
  }, [accountPulls, officeId, props.year]);

  function renderMonthPals(model, bucketMap, totals, first) {
    return <FinancialYearReportColumn
             model={model}
             bucketMap={bucketMap}
             totals={totals}
             color={props.color}
             enableSales={props.enableSales}
             enablePm={props.enablePm}
             enableDir={props.enableDir}
             showNamesAlways={props.showNamesAlways}
             first={first}
           />
  }

  function getModel(pull) {
    const officePals = pals.filter(i => i.office_id === officeId);
    const pullPals = officePals.filter(i => i.pull_id === pull.id);
    const pullSplits = splits.filter(i => i.pull_id === pull.id);
    const pullProRataSplits = proRataSplits.filter(i => i.id === pull.id);
    const pullAdjustments = adjustments.filter(i => i.pull_id === pull.id);

    const model = divisionalProfitAndLossModel(reapAccounts,
                                               pullPals,
                                               pullSplits,
                                               pullProRataSplits,
                                               pullAdjustments);

    return model;
  }

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    if (!modelsReady()) {
      return;
    }

    const { bucketMap, revenueExpense } = calculateAggregates();
    const columns = [];
    let first = true;
    for (const pull of finYearPulls) {
      const title = `${monthNames[pull.month]} ${pull.year}`;
      columns.push({
        title,
        render: ((first) => {
          return () => {
            const model = getModel(pull);
            return renderMonthPals(model, bucketMap, revenueExpense[pull.id], first);
          }
        })(first)
      });
      first = false;
    }
    setMonthColumns(columns);
  }, [
    pals,
    finYearPulls,
    props.color,
    props.enableSales,
    props.enablePm,
    props.enableDir,
    props.showNamesAlways
  ]);

  useEffect(() => {
    if (props.year !== null) {
      dispatch(action(REPORT_FIN_YEAR_PALS_GET, { officeId, year: props.year }));
      dispatch(action(REPORT_FIN_YEAR_HAS_DATA_GET, { officeId, year: props.year }));
    }
  }, [dispatch, props.year, officeId]);

  function modelsReady() {
    for (const pull of finYearPulls) {
      const model = getModel(pull);
      if (!model)
        return false;
    }

    return true;
  }

  function calculateAggregates() {
    const bucketMap = [];
    const revenueExpense = []
    for (const pull of finYearPulls) {
      const model = getModel(pull);

      if (!model) {
        return;
      }

      for (const b of model.reapBuckets) {
        if (!(b.name in bucketMap)) {
          bucketMap[b.name] = 0;
        }

        if (bucketMap[b.name] < b.accounts.length) {
          bucketMap[b.name] = b.accounts.length;
        }
      }

      const revenue = model.reapBuckets.filter(i => i.category === 'income')
        .reduce((acc, i) => acc + i.totalAmount, 0);
      const expense = model.reapBuckets.filter(i => i.category === 'expense')
        .reduce((acc, i) => acc + i.totalAmount, 0);

      revenueExpense[pull.id] = { revenue, expense };
    }

    return { bucketMap, revenueExpense }
  }

  if (yearData.length > 0) {
    if (!yearData[0].hasData) {
      return <p>No Data for Year</p>
    }
  }

  if (stillLoading()) {
    return <Loading />
  }

  return (
    <Col span={24}>
      <Table rowKey='id'
             dataSource={[{id: 'temp'}]}
             columns={monthColumns}
             pagination={false}
             size="small"
      />
    </Col>
  );
};

export default FinancialYearReport;
