import { fork } from "redux-saga/effects";
import axios from 'axios';

import accessControlSagas from './accessControlSagas.js';
import officeSagas from './officeSagas.js';
import accountingSagas from './accountingSagas.js';
import integrationSagas from "./integrationSagas.js";
import xeroSagas from "./xeroSagas.js";
import reapSagas from "./reapSagas.js";
import reportingSagas from "./reportingSagas.js";
import backgroundTaskSagas from "./backgroundTaskSagas.js";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export default function* sagas() {
  yield fork(accessControlSagas);
  yield fork(officeSagas);
  yield fork(integrationSagas);
  yield fork(xeroSagas);
  yield fork(accountingSagas);
  yield fork(reapSagas);
  yield fork(reportingSagas);
  yield fork(backgroundTaskSagas);
}
