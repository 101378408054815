import axios from 'axios';
import { call, put, takeLatest } from "redux-saga/effects";

import {
  action,
  XERO_GET_ACCOUNTS,
  XERO_PUT_ACCOUNTS,
  ACCOUNTING_START_PROCESS,
  ACCOUNTING_THREAD_CURRENT_GET,
  STORE_ACCOUNTING_THREAD_CURRENT,
  ACCOUNTING_THREAD_COMMIT_MAP,
  ACCOUNTING_PERIOD_SCHEDULE,
  XERO_ACCOUNTS_CHILD_ACCOUNT_POST,
  XERO_PALS_UPDATE_CHILDREN,
  ACC_PAL_ADJUSTMENT_ADD,
  ACC_PAL_ADJUSTMENT_REMOVE,
  ACC_PAL_ADJUSTMENT_STORE,
  ACC_PAL_ADJUSTMENT_UNSTORE,
  ACC_PAL_ADJUSTMENTS_GET,
  XERO_REAP_MAPPING_COMPLETION_PUT,
  ACC_PAL_JOINED_GET,
  ACC_PAL_JOINED_STORE,
  ACC_REAP_DIVISIONAL_SPLITS_GET,
  ACC_REAP_DIVISIONAL_SPLITS_STORE,
  ACC_REAP_DIVISIONAL_SPLITS_UPDATE,
  ACC_PAL_GET_PRO_RATA_SPLIT,
  ACC_PAL_GET_PRO_RATA_SPLIT_STORE,
  ACCOUNTING_PERIOD_SCHEDULE_MONTH,
  ACCOUNTING_PERIOD_SCHEDULE_YEAR,
  ACC_SPLIT_TEMPLATE_CREATE,
  ACC_SPLIT_TEMPLATE_STORE,
  ACC_SPLIT_TEMPLATES_GET,
  ACC_SPLIT_TEMPLATE_LINES_GET,
  ACC_SPLIT_TEMPLATE_LINES_STORE,
  ACC_SPLIT_TEMPLATE_APPLY,
  ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE
} from './actions.js';

import { getOfficeAccountStatusSaga } from "./officeSagas";

const newAction = action;

function* startAccountingProcessSaga(action) {
  const startAccountingProcess = async (postBody) => {
    const res = await axios.post('/accounting/start-accounting-process', postBody);
    return res.data;
  };

  yield call(startAccountingProcess, action.payload);
  yield call(getOfficeAccountStatusSaga, action.payload.officeId);
}

function* getAccountingThreadCurrentStepSaga(action) {
  const getAccountingThreadCurrentStep = async(officeId) => {
    const url = `/accounting/current-accounting-thread-step?officeId=${officeId}`;
    const res = await axios.get(url);
    return res.data;
  };

  const tcs = yield call(getAccountingThreadCurrentStep, action.payload);
  yield put(newAction(STORE_ACCOUNTING_THREAD_CURRENT, [tcs]));
};

function* getXeroAccountsSaga(action) {
  const getXeroAccounts = async(officeId) => {
    const url = `/accounting/xero-accounts?officeId=${officeId}`
    const res = await axios.get(url);
    return res.data;
  };

  const accounts = yield call(getXeroAccounts, action.payload);
  yield put(newAction(XERO_PUT_ACCOUNTS, accounts));
}

function* accountingThreadCommitMapSaga(action) {
  const accountingThreadCommitMap = async(postBody) => {
    const url = `/accounting/commit-mapping`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  const step = yield call(accountingThreadCommitMap, action.payload);
  yield put(newAction(STORE_ACCOUNTING_THREAD_CURRENT, [step]));
}

function* accountingPeriodScheduleSaga(action) {
  const accountingPeriodSchedule = async(postBody) => {
    const url = `/accounting/schedule-accounting-period`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  yield call(accountingPeriodSchedule, action.payload);
}

function* accountingPeriodScheduleSagaMonth(action) {
  const accountingPeriodScheduleMonth = async(postBody) => {
    const url = `/accounting/schedule-accounting-period-month`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  yield call(accountingPeriodScheduleMonth, action.payload);
}

function* accountingPeriodScheduleSagaYear(action) {
  const accountingPeriodScheduleYear = async (postBody) => {
    const url = `/accounting/schedule-accounting-period-year`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  yield call(accountingPeriodScheduleYear, action.payload);
}

export function* createXeroChildAccountSaga(action) {
  const createXeroChildAccount = async (body) => {
    try {
      const res = await axios.post('/accounting/create-child-account', body);
      const mappingComplete = res.headers['x-ri-mapping-complete'];
      return [res.data, mappingComplete === 'true'];
    }
    catch (err) {
    }
  };

  const [childAccount, mappingComplete] = yield call(createXeroChildAccount, action.payload);
  const completion = { id: action.payload.officeId, mappingComplete: mappingComplete };
  yield put(newAction(XERO_PUT_ACCOUNTS, [childAccount]));
  yield put(newAction(XERO_REAP_MAPPING_COMPLETION_PUT, [completion]));
};

export function* xeroPalsUpdateChildrenSaga(action) {
  const xeroPalsUpdateChildren = async (body) => {
    try {
      const res = await axios.post('/accounting/update-child-accounts', body);
      return res.data;
    }
    catch (err) {
    }
  };

  const childAccounts = yield call(xeroPalsUpdateChildren, action.payload);
  yield put(newAction(XERO_PUT_ACCOUNTS, childAccounts));
};

export function* accPalAdjustmentAddSaga(action) {
  const accPalAdjustmentAdd = async (body) => {
    try {
      const res = await axios.post('/accounting/add-pal-adjustment', body);
      return res.data;
    }
    catch (err) {
    }
  };

  const adjustment = yield call(accPalAdjustmentAdd, action.payload);
  yield put(newAction(ACC_PAL_ADJUSTMENT_STORE, [adjustment]));
};

export function* accPalAdjustmentRemoveSaga(action) {
  const accPalAdjustmentRemove = async (id) => {
    try {
      const url = `/accounting/remove-pal-adjustment?id=${id}`;
      await axios.delete(url);
    }
    catch (err) {
    }
  };

  yield call(accPalAdjustmentRemove, action.payload);
  yield put(newAction(ACC_PAL_ADJUSTMENT_UNSTORE, action.payload));
};

export function* accPalAdjustmentsGetSaga(action) {
  const accPalAdjustmentsGet = async (pullId) => {
    try {
      const url = `/accounting/get-pal-adjustments?pullId=${pullId}`;
      const res = await axios.get(url);
      return res.data;
    }
    catch (err) {
    }
  };

  const adjustments = yield call(accPalAdjustmentsGet, action.payload);
  yield put(newAction(ACC_PAL_ADJUSTMENT_STORE, adjustments));
};

export function* accPalJoinedGetSaga(action) {
  const accPalJoinedGet = async (params) => {
    try {
      const url = `/accounting/get-joined-reap-pal`;
      const res = await axios.get(url, { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const joined = yield call(accPalJoinedGet, action.payload);
  yield put(newAction(ACC_PAL_JOINED_STORE, joined));
};

export function* accReapDivisionalSplitsGetSaga(action) {
  const accReapDivisionalSplitsGet = async (params) => {
    try {
      const url = `/accounting/get-divisional-splits`;
      const res = await axios.get(url, { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const splits = yield call(accReapDivisionalSplitsGet, action.payload);
  yield put(newAction(ACC_REAP_DIVISIONAL_SPLITS_STORE, splits));
};

function* accReapDivisionalSplitsUpdateSaga(action) {
  const accReapDivisionalSplitsUpdate = async (postBody) => {
    const url = `/accounting/update-divisional-splits`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  yield put(newAction(ACC_REAP_DIVISIONAL_SPLITS_STORE, action.payload));
  const splits = yield call(accReapDivisionalSplitsUpdate, action.payload);
  yield put(newAction(ACC_REAP_DIVISIONAL_SPLITS_STORE, splits));
}

function* accPalGetProRataSplitSaga(action) {
  const accPalGetProRataSplit = async (params) => {
    try {
      const url = `/accounting/get-pro-rata-splits`;
      const res = await axios.get(url, { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const proRataSplit = yield call(accPalGetProRataSplit, action.payload);
  yield put(newAction(ACC_PAL_GET_PRO_RATA_SPLIT_STORE, proRataSplit));
}

function* accSplitTemplateCreateSaga(action) {
  const  accSplitTemplateCreate = async (postBody) => {
    const url = `/accounting/create-split-template`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  const splitTemplate = yield call(accSplitTemplateCreate, action.payload);
  yield put(newAction(ACC_SPLIT_TEMPLATE_STORE, [splitTemplate]));
}

function* accSplitTemplatesGetSaga(action) {
  const accSplitTemplatesGet = async (params) => {
    try {
      const url = `/accounting/get-split-templates`;
      const res = await axios.get(url, { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const splitTemplates = yield call(accSplitTemplatesGet, action.payload);
  yield put(newAction(ACC_SPLIT_TEMPLATE_STORE, splitTemplates));
}

function* accSplitTemplateLinesGetSaga(action) {
  const accSplitTemplateLinesGet = async (params) => {
    try {
      const url = `/accounting/get-split-template-lines`;
      const res = await axios.get(url, { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const splitTemplateLines = yield call(accSplitTemplateLinesGet, action.payload);
  yield put(newAction(ACC_SPLIT_TEMPLATE_LINES_STORE, splitTemplateLines));
}

function* accSplitTemplateApplySaga(action) {
  const  accSplitTemplateApply = async (postBody) => {
    const url = `/accounting/split-template-apply`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  const splits = yield call(accSplitTemplateApply, action.payload);
  yield put(newAction(ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE, { pullId: action.payload.pullId, splits }));
}

export default function* accountingSagas() {
  yield takeLatest(ACCOUNTING_START_PROCESS, startAccountingProcessSaga);
  yield takeLatest(ACCOUNTING_THREAD_CURRENT_GET, getAccountingThreadCurrentStepSaga);
  yield takeLatest(XERO_GET_ACCOUNTS, getXeroAccountsSaga);
  yield takeLatest(ACCOUNTING_THREAD_COMMIT_MAP, accountingThreadCommitMapSaga);
  yield takeLatest(ACCOUNTING_PERIOD_SCHEDULE,  accountingPeriodScheduleSaga);
  yield takeLatest(ACCOUNTING_PERIOD_SCHEDULE_MONTH,  accountingPeriodScheduleSagaMonth);
  yield takeLatest(ACCOUNTING_PERIOD_SCHEDULE_YEAR,  accountingPeriodScheduleSagaYear);
  yield takeLatest(XERO_ACCOUNTS_CHILD_ACCOUNT_POST, createXeroChildAccountSaga);
  yield takeLatest(XERO_PALS_UPDATE_CHILDREN, xeroPalsUpdateChildrenSaga);
  yield takeLatest(ACC_PAL_ADJUSTMENT_ADD, accPalAdjustmentAddSaga);
  yield takeLatest(ACC_PAL_ADJUSTMENT_REMOVE, accPalAdjustmentRemoveSaga);
  yield takeLatest(ACC_PAL_ADJUSTMENTS_GET, accPalAdjustmentsGetSaga);
  yield takeLatest(ACC_PAL_JOINED_GET, accPalJoinedGetSaga);
  yield takeLatest(ACC_REAP_DIVISIONAL_SPLITS_GET, accReapDivisionalSplitsGetSaga);
  yield takeLatest(ACC_REAP_DIVISIONAL_SPLITS_UPDATE, accReapDivisionalSplitsUpdateSaga);
  yield takeLatest(ACC_PAL_GET_PRO_RATA_SPLIT, accPalGetProRataSplitSaga);
  yield takeLatest(ACC_SPLIT_TEMPLATE_CREATE, accSplitTemplateCreateSaga);
  yield takeLatest(ACC_SPLIT_TEMPLATES_GET, accSplitTemplatesGetSaga);
  yield takeLatest(ACC_SPLIT_TEMPLATE_LINES_GET, accSplitTemplateLinesGetSaga);
  yield takeLatest(ACC_SPLIT_TEMPLATE_APPLY, accSplitTemplateApplySaga);
};
