import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Login from './features/Login/Login.js';
import Register from './features/Register/Register.js';
import AuthorizeMicrosoft from './features/AccessControl/AuthorizeMicrosoft.js';
import AuthorizeReap from './features/AccessControl/AuthorizeReap.js';
import './App.css';
import { action,
         READ_JWT_FROM_LOCAL_STORE
       } from './state/actions.js';
import AppAuthenticated from './features/AppAuthenticated/AppAuthenticated.js';
import AppUnAuthenticated from './features/AppUnauthenticated/AppUnauthenticated.js';
import AppIntegrations from './features/AppIntegrations/AppIntegrations.js';
import axios from 'axios';
import { ROUTE_LOGIN,
         ROUTE_REGISTER,
         ROUTE_ACCESS_CONTROL_MICROSOFT,
         ROUTE_ACCESS_CONTROL_REAP,
         ROUTE_INT_XERO_REDIRECT,
         ROUTE_REPORT_FIN_YEAR_PRINTABLE } from './AppRoutes.js';
import { Outlet, Navigate } from "react-router-dom";
import { jwtValid } from './utils/jwt.js';
import AuthorizeXero from './features/Integration/AuthorizeXero.js';
import AppPrintable from './features/AppAuthenticated/AppPrintable.js';

const PrivateRoute = () => {
  const jwt = window.localStorage.getItem('jwt');

  const location = useLocation()

  // Unauthorized Anonymous paths
  switch (location.pathname) {
    case ROUTE_LOGIN:
      return <Login/>
    case ROUTE_REGISTER:
      return <Register/>
    case ROUTE_ACCESS_CONTROL_MICROSOFT:
      return <AuthorizeMicrosoft/>
    case ROUTE_ACCESS_CONTROL_REAP:
      return <AuthorizeReap/>
    case ROUTE_INT_XERO_REDIRECT:
      return <AuthorizeXero/>
    default:
      break;
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return jwtValid(jwt) ? <Outlet /> : <Navigate to={ROUTE_LOGIN} />;
}

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const jwt = window.localStorage.getItem('jwt');

  const authenticated = jwtValid(jwt);
  if (jwtValid(jwt)) {
    axios.defaults.headers.common['Authorization']  = `Bearer ${jwt}`;
  };

  useEffect(() => {
    dispatch(action(READ_JWT_FROM_LOCAL_STORE));
  }, [dispatch]);

  const selectedJwt = useSelector(state => state.accessControl.jwt);
  if (authenticated && !jwtValid(selectedJwt)) {
    return null; // Wait for JWT to be available in state
  }


  const integration = location.pathname === ROUTE_INT_XERO_REDIRECT;
  const printable = location.pathname === ROUTE_REPORT_FIN_YEAR_PRINTABLE;
  let app = null;

  if (authenticated) {
    if (integration) {
      app = (
        <AppIntegrations>
          <PrivateRoute />
        </AppIntegrations>
      );
    } else if (printable) {
      app = (
        <AppPrintable>
          <PrivateRoute/>
        </AppPrintable>
      );
    } else {
      app = (
        <AppAuthenticated>
          <PrivateRoute />
        </AppAuthenticated>
      );
    }
  }
  else {
    app = (
     <AppUnAuthenticated>
         <PrivateRoute/>
     </AppUnAuthenticated>
    );
  }

  return (
    <div className='App'>
      {app}
    </div>
  );



}

export default App;
