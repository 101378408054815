import React from "react";
import AppAuthenticated from '../AppAuthenticated/AppAuthenticated.js';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const PermissionError = () => {
  return (
    <AppAuthenticated>
      <div style={{
             display: 'flex',
             alignItems: 'center',
             height:'100vh',
             flexDirection: 'column'
           }}>
        <div style={{marginTop: '200px'}}>
          <ExclamationCircleOutlined style={{fontSize: '3em'}}/>
        </div>
        <div>
          <p>You do not have the required permission to view this page.</p>
        </div>
      </div>
    </AppAuthenticated>
  );
};

export default PermissionError;
