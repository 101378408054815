const uniqueById = (array) => {
  const filtered = array.filter( (v,i,a) => a.findIndex( v2 => (v2.id === v.id)) === i );
  const sorted = filtered.sort((a, b) => a.id.localeCompare(b.id));
  return sorted;
};

const withoutId = (array, id) => {
  const filtered = array.filter(a => a.id !== id);
  return filtered;
};

const newArrayWithItem = (array, item) => {
  return uniqueById([...withoutId(array, item.id), item]);
};

const copy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

export {
  uniqueById,
  withoutId,
  newArrayWithItem,
  copy
}
