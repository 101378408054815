import React, { useState } from "react";
import {  Button, Popover } from 'antd';
import { PartitionOutlined } from '@ant-design/icons';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';


function BackgroundTaskMeta(props) {
  function generateJsonOutline(json) {
    return (
      <div>
        <JsonView data={JSON.parse(json)} shouldInitiallyExpand={allExpanded} style={defaultStyles} />
      </div>
    );
  }

  const [open, setOpen] = useState(false);

  return (
    <Popover
      content={generateJsonOutline(props.item)}
      title="Metadata"
      trigger="click"
      open={open}
      onOpenChange={value => setOpen(value)}>
      <Button shape="circle" icon={<PartitionOutlined />} size="small" style={{ color: '#3D426B' }}/>
    </Popover>
  );
}

export default BackgroundTaskMeta;
