import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const AppPrintable = (props) => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Content
        style={{
          padding: 24,
        }}
      >
        {props.children}
      </Content>
    </Layout>
  );
};

export default AppPrintable;
