import React from 'react';
import { Select, Row, Col } from 'antd';

const { Option } = Select;

function CompoundSplitRowAccountProRata(props) {

  const split = props.record.sub_split;
  const splitMeta = JSON.parse(split.split_meta);

  function onMappingChanged(division, value) {
    const meta = JSON.parse(split.split_meta);
    meta[division] = value;
    split.split_meta = JSON.stringify(meta);
    split.updated = true;
    props.updateSubSplit(split);
  }

  function renderXeroAccountSelector(division) {
    return (
      <Select
        showSearch
        style={{ width: 250 }}
        defaultValue={splitMeta[division]}
        onChange={(value) => onMappingChanged(division, value)}
        optionFilterProp="children"
        filterOption={(input, option) => {
          return option.label.toLowerCase().includes(input.toLowerCase());
        }}>
        <Option key="None" value="none" label="None">None</Option>
        {
          props.model.xeroAccounts.map(xeroAccount => {
            return <Option key={xeroAccount.id} value={xeroAccount.id} label={xeroAccount.name}>{xeroAccount.name}</Option>
          })
        }
      </Select>
    );
  }

  function getRowValue(division) {
    const id = splitMeta[division];
    if (id === 'none') {
      return 0;
    }
    const pal = props.model.xeroAccounts.find(a => a.id === id);
    return pal.amount;
  }

  function renderSelectedAccountValue(division) {
    const value = getRowValue(division);
    return (<p>{value.toFixed(2)}</p>);
  }

  function renderAccountRatio(division) {
    const ratios = props.model.getAccountBasedProRataRatio(splitMeta);
    const ratio = ratios[division];

    return ratio.toFixed(3);
  }

  return (
    <div>
      <div style={{ height: 180 }}>
        <Row>
          <Col span={8}><strong>Sales</strong></Col>
          <Col span={8}><strong>PM</strong></Col>
          <Col span={8}><strong>Dir</strong></Col>
        </Row>
        <Row style={{ marginTop: 10, marginLeft: -5 }}>
          <Col span={8}>{renderXeroAccountSelector('sales')}</Col>
          <Col span={8}>{renderXeroAccountSelector('pm')}</Col>
          <Col span={8}>{renderXeroAccountSelector('dir')}</Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={8}>{renderSelectedAccountValue('sales')}</Col>
          <Col span={8}>{renderSelectedAccountValue('pm')}</Col>
          <Col span={8}>{renderSelectedAccountValue('dir')}</Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={8}><strong>{renderAccountRatio('sales')}</strong></Col>
          <Col span={8}><strong>{renderAccountRatio('pm')}</strong></Col>
          <Col span={8}><strong>{renderAccountRatio('dir')}</strong></Col>
        </Row>
      </div>
    </div>
  );
}

export default CompoundSplitRowAccountProRata;
