import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Form, Input, Select, Button  } from 'antd';
import jwt_decode from "jwt-decode";
import PermissionError from '../Error/PermissionError.js';
import { ROLE_USER } from "../../const.js";
import { jwtValid } from '../../utils/jwt.js';
import log from '../../utils/log.js';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { action, ADD_NEW_OFFICE, SIGNAL_CLEAR } from '../../state/actions.js';
import countries from '../../state/countries.js';
import { ROUTE_OFFICE_VIEW } from '../../AppRoutes.js';

const AddOffice = () => {
  const { Option } = Select;
  const { Title } = Typography;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jwt = useSelector(state => state.accessControl.jwt);
  const newOfficeSignal = useSelector(state => {
    if (!state.signal) {
      return null;
    }

    if (state.signal.tag !== 'new-office') {
      return null;
    }

    return state.signal;
  });

  useEffect(() => {
    if (newOfficeSignal == null) {
      return;
    }

    const params = {
      id : newOfficeSignal.meta
    };

    dispatch(action(SIGNAL_CLEAR));

    navigate({
      pathname: ROUTE_OFFICE_VIEW,
      search: createSearchParams(params).toString()
    });

  }, [newOfficeSignal]);

  const [countryCode, setCountryCode] = useState('AUS');
  const [form] = Form.useForm();

  if (!jwtValid(jwt)) {
    return null;
  }

  var jwtDecoded = jwt_decode(jwt);

  const getPhonePrefix = () => {
    const prefix = countries.find(c => c.code === countryCode).phonePrefix;

    return (
    <Form.Item noStyle>
      +{prefix}
    </Form.Item>
    );
  };

  const onFinish = (values) => {
    dispatch(action(ADD_NEW_OFFICE, values));
  };

  const onFinishFailed = (errorInfo) => {
    log.error(JSON.stringify(errorInfo));
  };

  const renderCountryStates = (countryCode) => {
    const country = countries.find(c => c.code == countryCode);

    return country.states.map(s => {
      return (
        <Option value={s.code} key={s.code}>{s.state}</Option>
      );
    });
  };

  const renderCountries = () => {
    return countries.map(c => {
      return (
        <Option value={c.code} key={c.code}>{c.country}</Option>
      );
    });
  };

  const onCountryChanged = (cc) => {
    setCountryCode(cc);
    form.setFieldValue('state', null);
  };

  return (
    <div>
      <Title level={3}>Add Office</Title>
      <div style={{ marginTop: 40 }}>
        <Form
          form={form}
          name="add-office"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          style={{
            maxWidth: 680,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Office Name"
            rules={[
              {
                required: true,
                message: 'Please add your Office name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="street"
            label="Street Number, Name"
            rules={[
              {
                required: true,
                message: 'Please add your street number and name.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="post_code"
            label="Post Code"
            rules={[
              {
                required: true,
                message: 'Please add your post code.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please select your state.',
              },
            ]}
          >
            <Select placeholder="Please select a state.">
              {renderCountryStates(countryCode)}
            </Select>
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please add your phone number.',
              },
              () => ({
                validator(_, value) {
                  const isNum = /^\d+$/.test(value);
                  if (isNum) {
                    Promise.resolve();
                  } else {
                    return Promise.reject(new Error(`Must be a valid phone number. Only digits allowed.`));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              addonBefore={getPhonePrefix()}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item
            name="country"
            label="Country"
            hasFeedback
            initialValue={countryCode}
            rules={[
              {
                required: true,
                message: 'Please select your country.',
              },
            ]}
          >
            <Select placeholder="Please select a country" onChange={onCountryChanged}>
              {renderCountries()}
            </Select>
          </Form.Item>


          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
            style={{ marginTop: 60 }}
          >
            <Button type="primary" htmlType="submit" style={{ width: '45%' }}>
              Add Office
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddOffice;
