import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Table, Button, Select, Modal } from 'antd';
import DivisionalProfitAndLossColumn from './DivisionalProfitAndLossColumn';
import uuid from 'react-uuid';
import { action,
         ACC_REAP_DIVISIONAL_SPLITS_UPDATE,
         ACC_SPLIT_TEMPLATES_GET,
         ACC_SPLIT_TEMPLATE_APPLY
       } from  '../../state/actions.js';

import SaveTemplateModel from './SaveTemplateModel';

const { Option } = Select;

function MonthDivisionalProfitAndLoss(props) {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const [saveTemplateVisible, setSaveTemplateVisible] = useState(false);

  const officeId = searchParams.get("office_id");
  const pullId = searchParams.get("pull_id");

  function renderColumn(row_type, col_type, row) {
    return <DivisionalProfitAndLossColumn
             key={row.id}
             readOnly={props.readOnly}
             row_type={row_type}
             col_type={col_type}
             model={props.model}
             setSplitsState={props.setSplitsState}
             row={row}/>
  }

  const year = props.fromDate.getFullYear();
  const month = props.fromDate.toLocaleString('default', { month: 'short' });

  const monthHeader = `${year} - ${month}`;

  const columns = [
    {
      title: '',
      dataIndex: 'row_type',
      key: 'id',
      width: 20,
      render: (row_type, row) => renderColumn(row_type, 'control', row),
      align: 'center'
    },
    {
      title: '',
      dataIndex: 'row_type',
      key: 'id',
      width: 20,
      render: (row_type, row) => renderColumn(row_type, 'info', row),
      align: 'center'
    },
    {
      title: '',
      dataIndex: 'row_type',
      key: 'id',
      width: 20,
      render: (row_type, row) => renderColumn(row_type, 'code', row)
    },
    {
      title: '',
      dataIndex: 'row_type',
      key: 'type',
      width: 200,
      render: (row_type, row) => renderColumn(row_type, 'description', row)
    },
    {
      title: monthHeader,
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'month', row),
      align: 'right'
    },
    {
      title: 'Sales',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'sales', row),
      align: 'right'
    },
    {
      title: 'PM',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'pm', row),
      align: 'right'
    },
    {
      title: 'Dir',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'dir', row),
      align: 'right'
    },
  ];

  const ds = [];
  let sortOrder = 0;

  function push(item) {
    Object.assign(item, { sortOrder });
    ds.push(item);
    sortOrder++;
  }

  function pushEmpty() {
    push({
      id: uuid(),
      row_type: 'empty'
    });
  }

  function addReapBucketToDatasource(bucket) {
    if (bucket.accounts.length > 0) {
      const breakdown = {
        id: `${bucket.id}-breakdown`,
        target_id: bucket.id,
        row_type: 'breakdown',
        validation_status: bucket.validation_status,
        split: bucket.split
      }

      push(breakdown);
    }
    push(bucket);

    bucket.accounts.forEach(i => push(i));

    pushEmpty();
    insertTotalsAfterBucket(bucket);
  }

  props.model.reapBuckets.forEach(bucket => addReapBucketToDatasource(bucket));

  function insertTotalsAfterBucket(bucket) {
    switch (bucket.name) {
      case 'Other Income':
        pushTotal('Total Income', props.model.totals.totalIncome);
        pushEmpty();
        break;
      case 'Staff Consumables':
        pushTotal('Total Costs', props.model.totals.totalCosts);
        pushTotal('Gross Profit', props.model.totals.grossProfit);
        pushTotal('Gross Profit Margin', props.model.totals.grossProfitMargin);
        pushEmpty();
        break;
      case 'Administration':
        pushTotal('Total Operating Expenses', props.model.totals.totalOperatingExpenses);
        pushTotal('Operating Profit', props.model.totals.operatingProfit);
        pushEmpty();
        break;
    }
  }

  function pushTotal(name, value) {
    push({
      id: uuid(),
      row_type: 'total',
      name: name,
      amount: value
    });
  }

  pushTotal('Net Profit', props.model.totals.netProfit);
  pushTotal('Net Profit Percentage', props.model.totals.netProfitPercentage);

  function saveSplits() {
    const updatedSplits = props.model.splits.filter(i => i.updated === true);
    dispatch(action(ACC_REAP_DIVISIONAL_SPLITS_UPDATE, updatedSplits));
  }

  function saveTemplate() {
    setSaveTemplateVisible(true);
    dispatch(action(ACC_SPLIT_TEMPLATES_GET, { officeId }));
  }

  function onApplyTemplate(id) {
    Modal.confirm({
      title: 'Confirm Apply Template',
      content: 'Applying this template will overwrite all splits. Confirm?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(action(ACC_SPLIT_TEMPLATE_APPLY, { id, pullId }));
      },
    });
  }

  const validationErrors = props.model.reapBuckets.some(i => i.validation_status === 'error');
  const saveVisible = props.model.splits.some(i => i.updated === true) && (!validationErrors);

  function renderSaveTemplate() {
    if (!saveTemplateVisible) {
      return false;
    }

    return (<SaveTemplateModel
              setSaveTemplateVisible={setSaveTemplateVisible}
              saveTemplateVisible={saveTemplateVisible}
            />);
  }

  return (
    <div style={{ marginTop: 40 }}>
      {renderSaveTemplate()}
      {!props.readOnly &&
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Button type="primary"
                disabled={!saveVisible}
                onClick={saveSplits}>Save Splits</Button>
        <Button type="primary" style={{marginLeft: '10px'}}
                disabled={saveVisible}
                onClick={saveTemplate}>Save Template</Button>
        <Select
          key={uuid()}
          showSearch
          placeholder="Apply Template"
          style={{ width: 300, marginLeft: '10px' }}
          onChange={onApplyTemplate}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }}>

          {
            props.splitTemplates.map(option => {
              return (
                <Option key={option.id} value={option.id} label={option.name}>{option.name}</Option>
              )
            })
          }
        </Select >
      </div>
      }
      <Table
        rowKey="id"
        dataSource={ds}
        columns={columns}
        size="small"
        pagination={false}
        bordered
      />
    </div>
  );

}

export default MonthDivisionalProfitAndLoss;
