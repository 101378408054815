import React, { useState } from 'react';
import { Typography, DatePicker, Space, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { action, ACCOUNTING_START_PROCESS } from '../../state/actions.js';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { ROUTE_OFFICE_VIEW } from '../../AppRoutes.js';

const StartAccounting = () => {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("id");

  const { Title } = Typography;

  const [startDate, setStartDate] = useState(null);


  const onChangeDate = (_, d) => {
    setStartDate(d);
  };

  const startAccounting = () => {
    const asp = action(ACCOUNTING_START_PROCESS,
                       { officeId: id, fromDate: startDate });
    dispatch(asp);
    const params = {
      id
    };

    navigate({
      pathname: ROUTE_OFFICE_VIEW,
      search: createSearchParams(params).toString()
    });
  };

  const startDisabled = (startDate === null);

  return (
    <div>
      <Title level={4}>Start Accounting Process</Title>
      <p>Note it will not be possible to make alterations to external App attachements after this.</p>

      <Space direction="vertical">
        <p>Select Starting Date</p>
        <DatePicker onChange={ onChangeDate} />
        <Button disabled={startDisabled} onClick={startAccounting}>Start</Button>
      </Space>
    </div>
  );
};

export default StartAccounting;
