import React, { useState, useEffect } from "react";
import { Typography } from 'antd';
import { userIs } from '../../utils/jwt.js';
import { ROLE_ADMIN, ROLE_ROOT } from '../../const.js';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag } from 'antd';
import { action,
         BACKGROUND_TASKS_GET } from '../../state/actions.js';
import BackgroundTaskMeta from "./BackgroundTaskMeta.js";
import PermissionError from '../Error/PermissionError.js';
import BackgroundTaskError from "./BackgroundTaskError.js";

const BackgroundTasks = () => {
  const { Title } = Typography;

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(action(BACKGROUND_TASKS_GET, page));
  }, [dispatch, page]);

  const tasks = useSelector(state => state.system.backgroundTasks)
        .toSorted((a, b) => {
          const comparison = new Date(b.timestamp) - new Date(a.timestamp);
          return comparison;
        });
  const taskCount = useSelector(state => state.system.backgroundTaskCount);
  const jwt = useSelector(state => state.accessControl.jwt);

  if (!(userIs(jwt, ROLE_ADMIN) ||
    userIs(jwt, ROLE_ROOT))) {
    return (
      <PermissionError />
    );
  }

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 200,
      render: (item) => {
        const date = new Date(item);
        return `${date.toLocaleDateString()} : ${date.toLocaleTimeString()}`;
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        const color = state === 'completed' ? 'green' : 'red';
        return (
          <Tag color={color}>{state}</Tag>
        );
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Meta',
      dataIndex: 'meta',
      key: 'meta',
      render: (item) => {
        return <BackgroundTaskMeta
                 item={item}
               />
      }
    },
    {
      title: 'Error',
      dataIndex: 'error',
      key: 'error',
      render: (item) => {
        return <BackgroundTaskError
                 item={item}
               />
      }
    }
  ];


  const onPagination = (page) => {
    setPage(page - 1);
  };

  return (
    <div>
      <Title level={3}>Background Tasks</Title>
      <Table rowKey='id'
        dataSource={tasks}
        columns={columns}
        size="small"
        pagination={{ 'defaultCurrent': 1, 'total': taskCount, 'onChange': onPagination }} />
    </div>
  );
};

export default BackgroundTasks;
