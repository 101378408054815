import React, { useState } from 'react';
import { InputNumber, Table, Button } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';

function CompoundSplitRowPercentage(props) {
  const split = props.record.sub_split;
  const splitMeta = JSON.parse(split.split_meta);

  const dataSource = [
    { key: '1', sales: splitMeta.sales, pm: splitMeta.pm, dir: splitMeta.dir },
  ];

  function onPercentageChange(column, value) {
    if (value === null) {
      value = 0;
    }
    const meta = JSON.parse(split.split_meta);
    meta[column] = value;
    split.split_meta = JSON.stringify(meta);
    split.updated = true;

    props.updateSubSplit(split);
  }

  const columns = [
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('sales', value); }} />
        );
      }
    },
    {
      title: 'PM',
      dataIndex: 'pm',
      key: 'pm',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('pm', value); }} />
        );
      }
    },
    {
      title: 'Dir',
      dataIndex: 'dir',
      key: 'dir',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('dir', value); }} />
        );
      }
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource}
             columns={columns}
             pagination={false} />
    </div>
  );
}

export default CompoundSplitRowPercentage;
