import React, { useState } from 'react';
import { EllipsisOutlined, SwapOutlined } from '@ant-design/icons';
import { Menu, Button, Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { action, XERO_PAL_INVERT } from '../../state/actions.js';

function XeroProfitAndLossMenu(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  function onOpenChange(openState) {
    setOpen(openState);
  }

  function handleClick() {
    dispatch(action(XERO_PAL_INVERT, { id: props.record.id }));
    setOpen(false);
  }

  function renderPopup() {
    return (
      <div style={{ height: '50px', width: '200px' }}>
        <Menu mode="vertical">
          <Menu.Item key="invert" icon={<SwapOutlined />}
            onClick={handleClick}>
            Invert Value
          </Menu.Item>
        </Menu>
      </div>
    );
  }

  return (
    <Popover content={renderPopup()}
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}>
      <Button shape="circle" size="small"
        icon={<EllipsisOutlined style={{ color: 'blue' }} />} />
    </Popover>
  );
}

export default XeroProfitAndLossMenu;
