import React from 'react';
import { Card } from 'antd';

const InfoCard = (props) => {
  return (
    <Card
      style={{ width: 480, height: 440, marginTop: 180 }}>
      <div style={{
        display: 'flex',
        height: '100%',
        marginTop: '120px',
        justifyContent: 'center',
        alignItems: 'center'}}>
        {props.children}
      </div>
    </Card>
  );
};

export default InfoCard;
