import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../state/reducers.js';
import createSagaMiddleware from 'redux-saga';
import sagas from '../state/sagas.js'

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

sagaMiddleware.run(sagas);
