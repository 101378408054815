import {
  action,
  signal,
  AUTHORIZE_REAP,
  AUTHORIZE_MICROSOFT,
  REGISTER_NEW_REAP_USER,
  WRITE_JWT_TO_LOCAL_STORE,
  WRITE_INVALID_PASSWORD_STATE,
  SIGNAL_EVENT,
  AUTHORIZE_XERO,
  AUTHORIZE_XERO_FOR_OFFICE,
  GET_XERO_AUTH_URL,
  STORE_XERO_AUTH_URL,
  STORE_XERO_AUTH_STATE,
} from './actions.js';

import {
  ROUTE_LOGIN
} from '../AppRoutes.js';

import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';

const newAction = action;

function* authorizeMicrosoftSaga(action) {
  const authorizeMicrosoft = async (postBody) => {
    const res = await axios.post('/access-control/authorize-microsoft', postBody);
    return res.data;
  };

  const jwt = yield call(authorizeMicrosoft, action.payload);

  yield put(newAction(WRITE_JWT_TO_LOCAL_STORE, jwt));
}

function* registerNewReapUserSaga(action) {
  const registerNewReapUser = async (postBody) => {
    await axios.post('/access-control/register-reap', postBody);
  };

  yield call(registerNewReapUser, action.payload.values);
  action.payload.navigate(ROUTE_LOGIN);
}

function* authorizeReapSaga(action) {
  const authorizeReap = async (postBody) => {
    try {
      const res = await axios.post('/access-control/authorize-reap', postBody);
      return res.data;
    }
    catch (err) {
      if (err.response.status === 401) {
        return '';
      }
      else {
        throw err;
      }
    }
  };

  const jwt = yield call(authorizeReap, action.payload);
  if (jwt === '') {
    yield put(newAction(WRITE_INVALID_PASSWORD_STATE, true));
  }
  else {
    yield put(newAction(WRITE_JWT_TO_LOCAL_STORE, jwt));
  }
};

function* authorizeXeroSaga(action) {
  const authorizeXero = async (postBody) => {
    const res = await axios.post('/access-control/authorize-xero', postBody);
    return res.data;
  };

  const res = yield call(authorizeXero, action.payload);
  yield put(newAction(WRITE_JWT_TO_LOCAL_STORE, res));
};

function* authorizeXeroSagaForOffice(action) {
  const authorizeXero = async (postBody) => {
    const res = await axios.post('/access-control/authorize-xero-for-office', postBody);
    return res.data;
  };

  const res = yield call(authorizeXero, action.payload);
  yield put(newAction(SIGNAL_EVENT, signal('xero-authorized', res.officeId)));
};

function* getXeroAuthUrlSaga(action) {
  const getXeroAuthUrl = async () => {
    const url = `access-control/xero-get-login-url?id=${action.payload}`;
    const res = await axios.get(url);
    return res.data;
  };

  const xero = yield call(getXeroAuthUrl);
  yield put(newAction(STORE_XERO_AUTH_URL, xero.authUrl));
  yield put(newAction(STORE_XERO_AUTH_STATE, xero.authState));
};


export default function* accessControlSagas() {
  yield takeLatest(AUTHORIZE_MICROSOFT, authorizeMicrosoftSaga);
  yield takeLatest(REGISTER_NEW_REAP_USER, registerNewReapUserSaga);
  yield takeLatest(AUTHORIZE_REAP, authorizeReapSaga);
  yield takeLatest(AUTHORIZE_XERO, authorizeXeroSaga);
  yield takeLatest(AUTHORIZE_XERO_FOR_OFFICE, authorizeXeroSagaForOffice);
  yield takeLatest(GET_XERO_AUTH_URL, getXeroAuthUrlSaga);
};
