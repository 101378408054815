import React from 'react';
import { Tooltip, Button, InputNumber } from 'antd';
import { PercentageOutlined, InfoOutlined, EditOutlined } from '@ant-design/icons';
import { copy, withoutId } from '../../utils/data.js';
import uuid from 'react-uuid';
import DivisionalProfitAndLossMenu from './DivisionalProfitAndLossMenu.js';

function DivisionalProfitAndLossColumn(props) {
  if (props.row_type === 'empty') {
    return (
      <div style={{height: '22px'}}>
      </div>
    );
  }

  function onPercentageRenderSplitChange(column, value) {
    if (value === null) {
      value = 0;
    }

    const split = copy(props.row.split);
    const meta = JSON.parse(split.split_meta);
    meta[column] = value;
    split.split_meta = JSON.stringify(meta);
    split.updated = true;

    const newSplits = [...withoutId(props.model.splits, split.id), split];

    props.setSplitsState(newSplits);
  }

  function renderSplit(column) {
    if (props.row.split.split_type === 'percentage') {
      const meta = JSON.parse(props.row.split.split_meta);
      const columnPercentage = meta[column];

      return(<InputNumber
               key={`${props.row.id}-${column}`}
               addonBefore={<Tooltip title="Percentage based divisionalization." key={uuid()}><span style={{fontSize:'.8em'}}>PBDI</span></Tooltip>}
               addonAfter={<PercentageOutlined/>}
               max={100}
               min={0}
               status={props.row.validation_status}
               disabled={props.readOnly}
               defaultValue={columnPercentage.toFixed(0)}
               onChange={(value) => { onPercentageRenderSplitChange(column, value); }}/>);
    } else if (props.row.split.split_type === 'pro-rata') {
      const meta = JSON.parse(props.row.split.split_meta);
      const columnPercentage = meta[column];

      return (<InputNumber
                addonBefore={<Tooltip title="Income based pro-rata." key={uuid()}><span style={{fontSize:'.8em'}}>IBPR</span></Tooltip>}
                key={`${props.row.id}-${column}`}
                addonAfter={<PercentageOutlined />}
                max={100}
                min={0}
                disabled={true}
                value={columnPercentage.toFixed(2)} />);
    } else if (props.row.split.split_type === 'account-pro-rata') {
      const meta = JSON.parse(props.row.split.split_meta);
      const ratios = props.model.getAccountBasedProRataRatio(meta);
      const value = ratios[column] * 100;

      return (<InputNumber
                key={`${props.row.id}-${column}`}
                addonBefore={<Tooltip title="Account based pro-rata." key={uuid()}><span style={{fontSize:'.8em'}}>ABPR</span></Tooltip>}
                addonAfter={<PercentageOutlined />}
                max={100}
                min={0}
                disabled={true}
                value={value.toFixed(2)} />);
    }
  }

  function renderDescription() {
    switch (props.row.row_type) {
      case 'reap-account':
        if (props.row.accounts.length > 0) {
          const textColor = (props.row.category === 'income') ? '#165816' : '#c23b22';
          return (
            <strong style={{ color: textColor }}>{props.row.name}</strong>
          );
        } else {
          return renderReapAccountColumnColor(props.row.name)
        }
      case 'pal':
        return props.row.name;
      case 'adjustment':
        return props.row.note;
      case 'total':
        return <strong style={{color: '#3D426B'}}>{props.row.name}</strong>
      default:
        return null;
    }
  }

  function renderReapAccountColumnColor(text) {
    if (props.row.accounts.length > 0) {
      return <strong>{text}</strong>;
    }
    else {
      return <strong style={{ color: '#AAAAAA' }}>{text}</strong>;
    }
  }


  function renderMonth() {
    switch (props.row.row_type) {
      case 'reap-account':
        return renderReapAccountColumnColor(props.row.totalAmount.toFixed(2));
      case 'pal':
        return props.row.amount.toFixed(2);
      case 'adjustment':
        return props.row.amount.toFixed(2);
      case 'breakdown':
        return null;
      case 'total':
        const amount = props.row.amount.toFixed(2);
        if (props.row.name === 'Gross Profit Margin' ||
            props.row.name === 'Net Profit Percentage') {
          return <strong>{`${amount}%`}</strong>
        }
        return <strong>{amount}</strong>
      default:
        return null;
    }
  }

  function renderSales() {
    switch (props.row.row_type) {
      case 'reap-account':
        return <strong>{renderReapAccountColumnColor(props.row.divSales.toFixed(2))}</strong>;
      case 'pal':
        return props.row.divSales.toFixed(2);
      case 'adjustment':
        return props.row.divSales.toFixed(2);
      case 'breakdown':
        return renderSplit('sales');
      case 'total':
        return null;
      default:
        return null;
    }
  }

  function renderPM() {
    switch (props.row.row_type) {
      case 'reap-account':
        return <strong>{renderReapAccountColumnColor(props.row.divPm.toFixed(2))}</strong>;
      case 'pal':
        return props.row.divPm.toFixed(2);
      case 'adjustment':
        return props.row.divPm.toFixed(2);
      case 'breakdown':
        return renderSplit('pm');
      case 'total':
        return null;
      default:
        return null;
    }
  }

  function renderDir() {
    switch (props.row.row_type) {
      case 'reap-account':
        return <strong>{renderReapAccountColumnColor(props.row.divDir.toFixed(2))}</strong>;
      case 'pal':
        return props.row.divDir.toFixed(2);
      case 'adjustment':
        return props.row.divDir.toFixed(2);
      case 'breakdown':
        return renderSplit('dir');
      case 'total':
        return null;
      default:
        return null;
    }
  }

  function renderPopover() {
    if (props.row.row_type === 'total') {
      return null;
    }    

    return (
      <DivisionalProfitAndLossMenu
        row_type={props.row_type}
        row={props.row}
        readOnly={props.readOnly}
        model={props.model}
        setSplitsState={props.setSplitsState}
      />
    );
  }

  function renderInfo() {
    const info = [];

    if (props.row.isParent) {
      info.push(
        <Tooltip title="Has child accounts with values." key={uuid()}>
          <Button shape="circle" size="small"
            icon={<InfoOutlined style={{ color: 'green' }} />} />
        </Tooltip>
      )
    }

    if (props.row.row_type === 'adjustment') {
      info.push(
        <Tooltip title="Adjustment" key={uuid()}>
          <Button shape="circle" size="small"
            icon={<EditOutlined style={{ color: 'green' }} />} />
        </Tooltip>
      )
    }

    return info;
  }

  function renderCode() {
    if (props.row.row_type === 'pal') {
      return props.row.code;
    }
  }

  switch (props.col_type) {
    case 'control':
      return renderPopover();
    case 'info':
      return renderInfo();
    case 'code':
      return renderCode();
    case 'description':
      return renderDescription();
    case 'month':
      return renderMonth(props.row);
    case 'sales':
      return renderSales(props.row);
    case 'pm':
      return renderPM(props.row);
    case 'dir':
      return renderDir(props.row);
    default:
      return null;
  }

}

export default DivisionalProfitAndLossColumn;
