import React from 'react';
import { Typography, Table } from 'antd';
import uuid from 'react-uuid';
import './FinancialYearReportColumn.css';

function FinancialYearReportColumn(props) {
  const columns = [];

  if (props.showNamesAlways || props.first) {
    columns.push({
      title: '',
      dataIndex: 'row_type',
      key: 'type',
      width: 200,
      render: (row_type, row) => renderColumn(row_type, 'description', row)
    });
  }


  columns.push({
    title: 'Month',
    dataIndex: 'row_type',
    key: 'type',
    width: 50,
    render: (row_type, row) => renderColumn(row_type, 'month', row),
    align: 'right'
  });

  if (props.enableSales) {
    columns.push({
      title: 'Sales',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'sales', row),
      align: 'right'
    });
  }
  if (props.enablePm) {
    columns.push({
      title: 'PM',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'pm', row),
      align: 'right'
    });
  }
  if (props.enableDir) {
    columns.push({
      title: 'Dir',
      dataIndex: 'row_type',
      key: 'type',
      width: 50,
      render: (row_type, row) => renderColumn(row_type, 'dir', row),
      align: 'right'
    });
  }

  function textField(text, width=200, strong=false, color='#000000') {
    if (!props.color) {
      color='#000000';
    }

    return (
      <Typography.Text
        ellipsis={true}
        style={{ width: width, color: color }}
        strong={strong}>
        {text}
      </Typography.Text>
    );
  }

  function total(row) {
    if (row.category === 'income') {
      return props.totals.revenue;
    } else {
      return props.totals.expense;
    }
  }

  function rowColor(row) {
    const textColor = (row.category === 'income') ? '#165816' : '#c23b22';
    return textColor;
  }

  function renderDescription(row) {
    switch (row.row_type) {
      case 'reap-account':
        if (row.accounts.length > 0) {
          return textField(row.name, 200, true, rowColor(row));
        } else {
          return textField(row.name, 200, true, '#AAAAAA');
        }
      case 'pal':
          return textField(row.name, 200);
      case 'adjustment':
        return row.note;
      case 'total':
        return textField(row.name, 200, true, '#3D426B');
      default:
        return null;
    }
  }

  function renderMonth(row) {
    switch (row.row_type) {
      case 'reap-account':
        return textField(row.totalAmount.toFixed(2), 120, true);
      case 'pal':
        return textField(row.amount.toFixed(2), 120);
      case 'adjustment':
        return textField(row.amount.toFixed(2), 120);
      case 'breakdown':
        const bucket = props.model.findBucket(row.target_name);
        const percentage = bucket.totalAmount / total(bucket) * 100;
        const text = `${percentage.toFixed(2)}%`;
        return textField(text, 120, true, rowColor(bucket));
      case 'total':
        const amount = row.amount.toFixed(2);
        if (row.name === 'Gross Profit Margin' ||
            row.name === 'Net Profit Percentage') {
          return <strong>{`${amount}%`}</strong>
        }
        return <strong>{amount}</strong>
      default:
        return null;
    }
  }

  function renderSales(row) {
    if (!props.enableSales) {
      return;
    }
    switch (row.row_type) {
      case 'reap-account':
        return textField(row.divSales.toFixed(2), 120, true);
      case 'pal':
        return textField(row.divSales.toFixed(2), 120);
      case 'adjustment':
        return textField(row.divSales.toFixed(2), 120);
      case 'breakdown':
        const bucket = props.model.findBucket(row.target_name);
        const percentage = bucket.divSales / total(bucket) * 100;
        const text = `${percentage.toFixed(2)}%`;
        return textField(text, 120, true, rowColor(bucket));
      default:
        return null;
    }
  }

  function renderPM(row) {
    switch (row.row_type) {
      case 'reap-account':
        return textField(row.divPm.toFixed(2), 120, true);
      case 'pal':
        return textField(row.divPm.toFixed(2), 120);
      case 'adjustment':
        return textField(row.divPm.toFixed(2), 120);
      case 'breakdown':
        const bucket = props.model.findBucket(row.target_name);
        const percentage = bucket.divPm / total(bucket) * 100;
        const text = `${percentage.toFixed(2)}%`;
        return textField(text, 120, true, rowColor(bucket));
      default:
        return null;
    }
  }

  function renderDir(row) {
    switch (row.row_type) {
      case 'reap-account':
        return textField(row.divDir.toFixed(2), 120, true);
      case 'pal':
        return textField(row.divDir.toFixed(2), 120);
      case 'adjustment':
        return textField(row.divDir.toFixed(2), 120);
      case 'breakdown':
        const bucket = props.model.findBucket(row.target_name);
        const percentage = bucket.divDir / total(bucket) * 100;
        const text = `${percentage.toFixed(2)}%`;
        return textField(text, 120, true, rowColor(bucket));
      default:
        return null;
    }
  }

  function renderColumn(row_type, col_type, row) {
    if (row_type === 'empty') {
      return (<br/>);
    }

    switch(col_type) {
      case 'description':
        return renderDescription(row);
      case 'month':
        return renderMonth(row);
      case 'sales':
        return renderSales(row);
      case 'pm':
        return renderPM(row);
      case 'dir':
        return renderDir(row);
      default:
        return "<<<EMPTY>>>";
    }
  }

  const ds = [];
  let sortOrder = 0;

  function push(item) {
    Object.assign(item, { sortOrder });
    ds.push(item);
    sortOrder++;
  }

  function pushEmpty() {
    push({
      id: uuid(),
      row_type: 'empty'
    });
  }

  function insertTotalsAfterBucket(bucket) {
    switch (bucket.name) {
      case 'Other Income':
        pushTotal('Total Income', props.model.totals.totalIncome);
        pushEmpty();
        break;
      case 'Staff Consumables':
        pushTotal('Total Costs', props.model.totals.totalCosts);
        pushTotal('Gross Profit', props.model.totals.grossProfit);
        pushTotal('Gross Profit Margin', props.model.totals.grossProfitMargin);
        pushEmpty();
        break;
      case 'Administration':
        pushTotal('Total Operating Expenses', props.model.totals.totalOperatingExpenses);
        pushTotal('Operating Profit', props.model.totals.operatingProfit);
        pushEmpty();
        break;
    }
  }

  function addReapBucketToDatasource(bucket) {
    if (bucket.accounts.length > 0) {
      const breakdown = {
        id: `${bucket.id}-breakdown`,
        target_id: bucket.id,
        target_name: bucket.name,
        row_type: 'breakdown',
        validation_status: bucket.validation_status,
        split: bucket.split
      }

      push(breakdown);
    }
    push(bucket);

    bucket.accounts.forEach(i => push(i));
    const padding = props.bucketMap[bucket.name] - bucket.accounts.length;
    for (let i = 0; i < padding; i++) {
      pushEmpty();
    }

    pushEmpty();
    insertTotalsAfterBucket(bucket);
  }

  props.model.reapBuckets.forEach(bucket => addReapBucketToDatasource(bucket));

  function pushTotal(name, value) {
    push({
      id: uuid(),
      row_type: 'total',
      name: name,
      amount: value
    });
  }

  pushTotal('Net Profit', props.model.totals.netProfit);
  pushTotal('Net Profit Percentage', props.model.totals.netProfitPercentage);

  return (
    <Table rowKey='id'
           dataSource={ds}
           columns={columns}
           pagination={false}
           size="small"
           style={{margin:'0px'}}
    />
  );
}

export default FinancialYearReportColumn;
