import React, { useState } from 'react';
import { Table, Button, Popover, InputNumber } from 'antd';
import { EllipsisOutlined, PercentageOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { UpCircleOutlined, PieChartOutlined, ContainerOutlined, OrderedListOutlined } from '@ant-design/icons';
import { copy } from '../../utils/data.js';
import { withoutId } from '../../utils/data.js';
import PercentageSplitSubmenu from './PercentageSplitSubmenu.js';
import AccountBasedProRataSplitSubMenu from './AccountBasedProRataSplitSubMenu.js';
import CompoundSplitMenu from './CompoundSplitMenu.js';

function DivisionalProfitAndLossMenu(props) {

  const [menuVisible, setMenuVisible] = useState(true);
  const [percentageVisible, setPercentageVisible] = useState(false);
  const [accountProRataVisible, setAccountProRataVisible] = useState(false);
  const [compoundSplitVisible, setCompoundSplitVisible] = useState(false);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(250);
  const [open, setOpen] = useState(false);

  if (props.row_type === 'breakdown') {
    return null;
  }

  function renderPercentageSplit() {
    return <PercentageSplitSubmenu
             model={props.model}
             row={props.row}
             setSplitsState={props.setSplitsState}
             setMenuVisible={setMenuVisible}
             setPercentageVisible={setPercentageVisible}
             setOpen={setOpen}
           />
  }

  function renderAccountBasedProRata() {
    return <AccountBasedProRataSplitSubMenu
             model={props.model}
             row={props.row}
             setMenuVisible={setMenuVisible}
             setSplitsState={props.setSplitsState}
             setAccountProRataVisible={setAccountProRataVisible}
           />
  }

  function renderCompoundSplit() {
    return <CompoundSplitMenu
             model={props.model}
             row={props.row}
             setMenuVisible={setMenuVisible}
             setSplitsState={props.setSplitsState}
             setCompoundSplitVisible={setCompoundSplitVisible}
           />
  }

  function renderMenu() {
    const splitRow = props.row;

    const defaultSelectedKeys = [splitRow.split.split_type];
    const isReapBucket = props.row.row_type === 'reap-account';

    function handleClick(e) {
      if (props.readOnly) {
        return;
      }

      const splitType = e.key;

      if (splitType === 'parent') {
        const split = copy(props.row.split);
        split.split_type = splitType;
        split.split_meta = '';
        split.updated = true;
        const newSplits = [...withoutId(props.model.splits, split.id), split];
        props.setSplitsState(newSplits);
      } else if (splitType === 'percentage') {
        setMenuVisible(false);
        setPercentageVisible(true);
        setHeight(200);

        const split = copy(props.row.split);
        if (split.split_type !== splitType) {
          split.split_type = splitType;
          split.split_meta = JSON.stringify({
            sales: 0,
            pm: 0,
            dir: 0
          });
          split.updated = true;
        }

        const newSplits = [...withoutId(props.model.splits, split.id), split];
        props.setSplitsState(newSplits);
      } else if (splitType === 'pro-rata') {
        const split = copy(props.row.split);
        if (split.split_type !== splitType) {
          split.split_type = splitType;
          split.split_meta = JSON.stringify(props.model.proRataSplits[0]);
          split.updated = true;
          const newSplits = [...withoutId(props.model.splits, split.id), split];
          props.setSplitsState(newSplits);
        }
      } else if (splitType === 'account-pro-rata') {
        setMenuVisible(false);
        setAccountProRataVisible(true);
        setWidth(800);
        setHeight(240);

        const split = copy(props.row.split);
        if (split.split_type !== splitType) {
          split.split_type = splitType;
          split.split_meta = JSON.stringify({
            sales: 'none',
            pm: 'none',
            dir: 'none'
          });
          split.updated = true;
        }

        const newSplits = [...withoutId(props.model.splits, split.id), split];
        props.setSplitsState(newSplits);
      } else if (splitType === 'compound-splits') {
        setMenuVisible(false);
        setCompoundSplitVisible(true);
        setWidth(800);
        setHeight(400);
        const split = copy(props.row.split);

        if (split.split_type !== splitType) {
          split.split_type = splitType;
          split.split_meta = JSON.stringify([]);
          split.updated = true;
        }

        const newSplits = [...withoutId(props.model.splits, split.id), split];
        props.setSplitsState(newSplits);
      }
    }

    return (
      <Menu mode="vertical"
        defaultSelectedKeys={defaultSelectedKeys}
        selectable={!props.readOnly}>
        <Menu.Item key="parent" icon={<UpCircleOutlined />}
          disabled={isReapBucket}
          onClick={handleClick}>
          Inherit from Parent
        </Menu.Item>
        <Menu.Item key="percentage"
          icon={<PercentageOutlined />}
          onClick={handleClick}>
          Percentage Split
        </Menu.Item>
        <Menu.Item key="pro-rata"
          icon={<PieChartOutlined />}
          onClick={handleClick}>
          Income Based Pro-Rata Split
        </Menu.Item>
        <Menu.Item key="account-pro-rata"
          icon={<ContainerOutlined />}
          onClick={handleClick}>
          Account Based Pro-Rata
        </Menu.Item>
        <Menu.Item key="compound-splits"
          disabled={isReapBucket}
          icon={<OrderedListOutlined />}
          onClick={handleClick}>
          Compound Splits
        </Menu.Item>
      </Menu>
    );
  }

  function renderPopup() {
    let childContent = null;

    if (menuVisible) {
      childContent = renderMenu();
    } else if (percentageVisible) {
      childContent = renderPercentageSplit();
    } else if (accountProRataVisible) {
      childContent = renderAccountBasedProRata();
    } else if (compoundSplitVisible) {
      childContent = renderCompoundSplit();
    }

    return (
      <div style={{height: height, width: width}}>
        {childContent}
      </div>
    );
  }

  function onOpenChange(openState) {
    setOpen(openState);
  }

  return (
    <Popover content={renderPopup()}
             trigger="click"
             open={open}
             onOpenChange={onOpenChange}>
      <Button shape="circle" size="small"
        icon={<EllipsisOutlined style={{ color: 'blue' }} />} />
    </Popover>
  );
};

export default DivisionalProfitAndLossMenu;
