import axios from 'axios';
import { call, put, takeLatest } from "redux-saga/effects";

import {
  action,
  signal,
  ADD_NEW_OFFICE,
  STORE_OFFICES,
  SIGNAL_EVENT,
  GET_OFFICE_SINGLE,
  GET_OFFICE_ALL,
  GET_OFFICE_ALL_BY_USER,
  STORE_OFFICE_COUNT,
  STORE_OAS,
  GET_OAS,
  SET_OAS
} from './actions.js';

const newAction = action;


export function* addNewOfficeSaga(action) {
  const addNewOffice = async (postBody) => {
    try {
      const res = await axios.post('/office/create', postBody);
      return res.data;
    }
    catch (err) {
    }
  };

  const office = yield call(addNewOffice, action.payload);
  yield put(newAction(STORE_OFFICES, [office]));
  yield put(newAction(SIGNAL_EVENT, signal('new-office', office.id)));
};

export function* getOfficeSingleSaga(action) {
  const getOfficeSingle = async (id) => {
    try {
      const params = { id };

      const res = await axios.get('/office/get-single', { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const office = yield call(getOfficeSingle, action.payload);
  yield put(newAction(STORE_OFFICES, [office]));
}

export function* getOfficeAllByUserSaga(action) {
  const getOfficeAllByUser = async (page) => {
    try {
      const params = { page };

      const res = await axios.get('/office/get-all-by-user', { params });
      const count = res.headers['x-ri-count'];
      return [res.data, count];
    }
    catch (err) {
    }
  };

  const [offices, count] = yield call(getOfficeAllByUser, action.payload);
  yield put(newAction(STORE_OFFICES, offices));
  yield put(newAction(STORE_OFFICE_COUNT, count));
};

export function* getOfficeAllSaga(action) {
  const getOfficeAll = async (page) => {
    try {
      const params = { page };

      const res = await axios.get('/office/get-all', { params });
      const count = res.headers['x-ri-count'];
      return [res.data, count];
    }
    catch (err) {

    }
  };

  const [offices, count] = yield call(getOfficeAll, action.payload);
  yield put(newAction(STORE_OFFICES, offices));
  yield put(newAction(STORE_OFFICE_COUNT, count));
};

export function* getOfficeAccountStatusSaga(action) {
  const getOfficeAccountStatus = async (officeId) => {
    try {
      const params = { officeId };

      const res = await axios.get('/office/get-account-status-by-id', { params });
      return res.data;
    }
    catch (err) {

    }
  };

  const oas = yield call(getOfficeAccountStatus, action.payload);
  yield put(newAction(STORE_OAS, [oas]));
};

export function* setOfficeAccountStatusSaga(action) {
  const setOfficeAccountStatus = async (postBody) => {
    const res = await axios.post('/office/set-account-status-by-id', { postBody });
    return res.data;
  }

  const oas = yield call(setOfficeAccountStatus, action.payload);
  yield put(newAction(STORE_OAS, [oas]));
}

export default function* officeSagas() {
  yield takeLatest(ADD_NEW_OFFICE, addNewOfficeSaga);
  yield takeLatest(GET_OFFICE_SINGLE, getOfficeSingleSaga);
  yield takeLatest(GET_OFFICE_ALL_BY_USER, getOfficeAllByUserSaga);
  yield takeLatest(GET_OFFICE_ALL, getOfficeAllSaga);
  yield takeLatest(GET_OAS, getOfficeAccountStatusSaga);
  yield takeLatest(SET_OAS, setOfficeAccountStatusSaga);
}
