import axios from 'axios';
import { call, put, takeLatest } from "redux-saga/effects";

import {
  action,
  BACKGROUND_TASKS_GET,
  BACKGROUND_TASKS_STORE,
  BACKGROUND_TASKS_STORE_COUNT
} from './actions.js'

const newAction = action;

export function* getBackgroundTasksSaga(action) {
  const getBackgroundTasks = async (page) => {
    try {
      const params = { page };

      const res = await axios.get('/background-tasks/get-tasks', { params });
      const count = res.headers['x-ri-count'];
      return [res.data, count];
    }
    catch (err) {
    }
  };

  const [tasks, count] = yield call(getBackgroundTasks, action.payload);
  yield put(newAction(BACKGROUND_TASKS_STORE, tasks));
  yield put(newAction(BACKGROUND_TASKS_STORE_COUNT, count));
};

export default function* backgroundTaskSagas() {
  yield takeLatest(BACKGROUND_TASKS_GET, getBackgroundTasksSaga);
}
