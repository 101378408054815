import React from 'react';
import { useSearchParams } from 'react-router-dom';
import FinancialYearReport from './FinancialYearReport';

function FinancialYearReportPrint() {
  const [searchParams, _] = useSearchParams();

  const year = searchParams.get("year");
  const color = searchParams.get("color") === 'true';
  const enableSales = searchParams.get("enableSales") === 'true';
  const enablePm = searchParams.get("enablePm") === 'true';
  const enableDir = searchParams.get("enableDir") === 'true';
  const showNamesAlways = searchParams.get("showNamesAlways") === 'true';

  return (
    <FinancialYearReport
      year={year}
      color={color}
      enableSales={enableSales}
      enablePm={enablePm}
      enableDir={enableDir}
      showNamesAlways={showNamesAlways}
    />
  );
}

export default FinancialYearReportPrint;
