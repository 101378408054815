import React from 'react';
import { withoutId } from '../../utils/data.js';
import { InputNumber, Table, Button } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';

function PercentageSplitSubmenu(props) {
  const split = props.row.split;
  const splitMeta = JSON.parse(split.split_meta);
  const status = props.row.validation_status;

  function onPercentageChange(column, value) {
    if (value === null) {
      value = 0;
    }
    const meta = JSON.parse(split.split_meta);
    meta[column] = value;
    split.split_meta = JSON.stringify(meta);
    split.updated = true;

    const newSplits = [...withoutId(props.model.splits, split.id), split];
    props.setSplitsState(newSplits);
  }

  const dataSource = [
    { key: '1', sales: splitMeta.sales, pm: splitMeta.pm, dir: splitMeta.dir },
  ];

  const columns = [
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          status={status}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('sales', value); }} />
        );
      }
    },
    {
      title: 'PM',
      dataIndex: 'pm',
      key: 'pm',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          status={status}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('pm', value); }} />
        );
      }
    },
    {
      title: 'Dir',
      dataIndex: 'dir',
      key: 'dir',
      width: 100,
      align: 'right',
      render: (value) => {
        return (<InputNumber
          key={'sales-input'}
          addonAfter={<PercentageOutlined />}
          max={100}
          min={0}
          status={status}
          defaultValue={value.toFixed(0)}
          onChange={(value) => { onPercentageChange('dir', value); }} />
        );
      }
    },
  ];

  function back() {
    props.setMenuVisible(true);
    props.setPercentageVisible(false);
  }

  function ok() {
    props.setOpen(false);
  }

  return (
    <div>
      <Table dataSource={dataSource}
        columns={columns}
        pagination={false} />
      <Button type="default"
        style={{
          marginTop: '20px',
          float: 'right',
          marginRight: '10px'
        }}
        onClick={back}>Back</Button>
      <Button type="primary"
        style={{
          marginTop: '20px',
          float: 'right',
          marginRight: '10px'
        }}
        onClick={ok}>OK</Button>
    </div>
  );
}

export default PercentageSplitSubmenu;
