import React, { useState } from "react";
import {  Button, Popover } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';
import 'react-json-view-lite/dist/index.css';


function BackgroundTaskError(props) {
  function generateError(error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  const [open, setOpen] = useState(false);
  const disabled = props.item === null;

  function onOpenChange(value) {
    if (props.item !== null) {
      setOpen(value);
    }
  }

  return (
    <Popover
      content={generateError(props.item)}
      title="Error"
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}>
      <Button shape="circle"
              icon={<ExclamationOutlined />}
              size="small"
              style={{ color: '#3D426B' }}
              disabled={disabled}/>
    </Popover>
  );
}

export default BackgroundTaskError;
