import React from 'react';
import { Table, Button } from 'antd';

import uuid from 'react-uuid';

import CompoundSplitRowMenu from './CompoundSplitRowMenu.js';

function CompoundSplitMenu(props) {

  function toFixed(item) {
    if (item !== undefined) {
      return item.toFixed(2);
    }
  }

  function renderRemainder(item) {
    const fixed = toFixed(item);
    const color = item > 0.0000001 ? '#ff6961' : '#c1e1c1';
    return <strong style={{ color: color }}>{fixed}</strong>
  }

  const columns = [
    {
      title: 'Split',
      dataIndex: 'split',
      key: 'split',
      render: (item, record) => {
        return <CompoundSplitRowMenu
          item={item}
          record={record}
          row={props.row}
          model={props.model}
          setSplitsState={props.setSplitsState} />
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (item) => toFixed(item)
    },
    {
      title: 'Sales',
      dataIndex: 'divSales',
      key: 'divSales',
      render: (item) => toFixed(item)
    },
    {
      title: 'PM',
      dataIndex: 'divPm',
      key: 'divPm',
      render: (item) => toFixed(item)
    },
    {
      title: 'Dir',
      dataIndex: 'divDir',
      key: 'divDir',
      render: (item) => toFixed(item)
    },
    {
      title: 'Remainder',
      dataIndex: 'remainder',
      key: 'remainder',
      render: (item) => renderRemainder(item)
    },
  ];

  const datasource = [];
  let sortOrder = 0;

  function push(item) {
    const id = uuid();
    Object.assign(item, { id, sortOrder });
    datasource.push(item);
    sortOrder++;
  }

  push({
    split: '',
    row_type: 'header',
    divSales: 0,
    divPm: 0,
    divDir: 0,
    total: props.row.totalAmount,
    remainder: props.row.totalAmount
  });

  for (const sb of props.row.subBreakdowns) {
    push({
      split: '',
      row_type: 'subSplit',
      ...sb
    });
  }

  push({
    row_type: 'footer',
  });

  function back() {
    props.setMenuVisible(true);
    props.setCompoundSplitVisible(false);
  }


  return (
    <div>
      <Table dataSource={datasource}
             columns={columns}
             pagination={false}
      />
      <Button type="default"
        style={{
          marginTop: '20px',
          float: 'right',
          marginRight: '10px'
        }}
        onClick={back}>Back</Button>
    </div>
  );
}

export default CompoundSplitMenu;
