import NotFoundError from '../Error/NotFoundError.js';
import React, { useEffect } from 'react';
import { Typography, Col, Row, Descriptions, Button } from 'antd';
import { getStateFromCode, getCountryFromCode } from "../../state/countries.js";
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { action,
         GET_OFFICE_SINGLE,
         CHECK_TOKEN_EXISTS,
         GET_OAS,
         GET_TENANTS } from '../../state/actions.js';
import XeroIntegration from './XeroIntegration.js';
import Loading from '../Loading/Loading.js';
import jwt_decode from "jwt-decode";
import { ROUTE_OFFICE_START_ACCOUNTS } from '../../AppRoutes.js';
import ViewOfficeAdmin from './ViewOfficeAdmin';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

const ViewOffice = () => {
  const { Title } = Typography;
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = searchParams.get("id");

  useEffect(() => {
    dispatch(action(GET_OFFICE_SINGLE, id), [id]);
    dispatch(action(GET_OAS, id), [id]);
    dispatch(action(CHECK_TOKEN_EXISTS, {
      officeId: id,
      application: 'xero'
    }));
    dispatch(action(GET_TENANTS, { officeId: id }));
  }, [dispatch, id]);

  const offices = useSelector(state => state.offices.items);
  const accountStatus = useSelector(state => state.offices.accountStatus);
  const tokenExists = useSelector(state => state.tokens.tokenExists);
  const jwt = useSelector(state => state.accessControl.jwt);
  const tenants = useSelector(state => state.xero.tenants);

  const jwtDecoded = jwt_decode(jwt);

  const office = offices.find(o => o.id === id);
  const officeAccountStatus = accountStatus.find(s => s.office_id === id);
  const xeroTokenExists = tokenExists.find(t => {
    return (t.userId === jwtDecoded.id &&
            t.officeId === id &&
            t.application === 'xero');
  });
  const officeTenants = tenants.filter(c => c.office_id === id);

  const startAccountingProcess = () => {
    const params = {
      id
    };

    navigate({
      pathname: ROUTE_OFFICE_START_ACCOUNTS,
      search: createSearchParams(params).toString()
    });
  };

  if (!id) {
    return (
      <NotFoundError />
    );
  }

  if (!(office && officeAccountStatus) || (xeroTokenExists === undefined) ) {
    return (
      <Loading />
    );
  }

  const startAccountingEnabled = officeAccountStatus.status === 'created'
        && xeroTokenExists.exists
        && officeTenants.length > 0;

  const renderStartAccountingButton = () => {
    if (!startAccountingEnabled) {
      return null;
    }

    return (
      <Row gutter={48} style={{ marginTop: '40px' }}>
        <Col span={12}>
          <Button style={{ marginTop: '40px' }} type="primary"
            onClick={startAccountingProcess}>Start Accouting Process</Button>
        </Col>
      </Row>
    );
  };

  const renderXeroIntegration = () => {
    return (
      <Row gutter={48}>
        <Col span={12}>
          <XeroIntegration officeId={id} />
        </Col>
      </Row>
    );
  };

  const renderAccountingCommittedView = () => {
    if ( officeAccountStatus.status !== 'committed' ) {
      return null;
    }

    return (
      <div>
        <p>Accounting Process started for this office.</p>
      </div>
    );
  };

  const renderOfficeAdmin = () => {
    return (
      <ViewOfficeAdmin officeId={id}/>
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={3}> {office.name}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p><MailOutlined style={{marginRight: '10px'}}/>{`${office.street}, ${office.post_code}, ${getStateFromCode(office.country, office.state)}, ${getCountryFromCode(office.country)}`}</p>

          <p><PhoneOutlined style={{marginRight: '10px'}}/>{office.phone}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {renderAccountingCommittedView()}
          {renderStartAccountingButton()}
          {renderXeroIntegration()}
          {renderOfficeAdmin()}
        </Col>
      </Row>
    </div>
  );
};
export default ViewOffice;
