import uuid from 'react-uuid';

export const action = (type, payload={}) => ({
  type,
  payload
});

export const signal = (tag, meta) => ({
  id: uuid(),
  tag,
  meta
});

export const signalMatch = (signal, tag, meta) => {
  if (!signal) {
    return false;
  }

  if (signal.tag !== tag) {
    return false;
  }

  if (signal.meta === meta) {
    return true;
  }

  return false;
};

export const READ_JWT_FROM_LOCAL_STORE = 'READ_JWT_FROM_LOCAL_STORE';
export const WRITE_JWT_TO_LOCAL_STORE = 'WRITE_JWT_TO_LOCAL_STORE';

export const STORE_AUTH_STATE = 'STORE_AUTH_STATE';
export const READ_AUTH_STATE = 'READ_AUTH_STATE';

export const AUTHORIZE_MICROSOFT = 'AUTHORIZE_MICROSOFT';
export const AUTHORIZE_REAP = 'AUTHORIZE_REAP';
export const WRITE_INVALID_PASSWORD_STATE = 'WRITE_INVALID_PASSWORD_STATE';

export const REGISTER_NEW_REAP_USER = 'REGISTER_NEW_REAP_USER';

export const ADD_NEW_OFFICE = 'ADD_NEW_OFFICE';
export const STORE_OFFICES = 'STORE_OFFICES';
export const GET_OFFICE_SINGLE = 'GET_OFFICE_SINGLE';
export const GET_OFFICE_ALL_BY_USER = 'GET_OFFICE_ALL_BY_USER';
export const GET_OFFICE_ALL = 'GET_OFFICE_ALL';
export const STORE_OFFICE_COUNT = 'STORE_OFFICE_COUNT';
export const SIGNAL_EVENT = 'SIGNAL_EVENT';
export const SIGNAL_CLEAR = 'SIGNAL_CLEAR';

export const AUTHORIZE_XERO = 'AUTHORIZE_XERO';
export const AUTHORIZE_XERO_FOR_OFFICE = 'AUTHORIZE_XERO_FOR_OFFICE';
export const STORE_XERO_AUTH_STATE = 'STORE_XERO_AUTH_STATE';
export const READ_XERO_AUTH_STATE = 'READ_XERO_AUTH_STATE';
export const STORE_XERO_AUTH_URL = 'STORE_XERO_AUTH_URL';
export const GET_XERO_AUTH_URL = 'GET_XERO_AUTH_URL';
export const READ_XERO_AUTH_URL = 'READ_XERO_AUTH_URL';
export const CHECK_TOKEN_EXISTS = 'CHECK_TOKEN_EXISTS';
export const STORE_TOKEN_EXISTS = 'STORE_TOKEN_EXISTS';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const UNSTORE_TOKEN_EXISTS = 'UNSTORE_TOKEN_EXISTS';

export const GET_CONNECTIONS = 'GET_CONNECTIONS';
export const STORE_CONNECTIONS = 'STORE_CONNECTIONS';
export const GET_TENANTS = 'GET_TENANTS';
export const STORE_TENANTS = 'STORE_TENANTS';
export const UPSERT_TENANTS = 'UPSERT_TENANTS';
export const STORE_OAS = 'STORE_OAS';
export const GET_OAS = 'GET_OAS';
export const SET_OAS = 'SET_OAS';

export const ACCOUNTING_START_PROCESS = 'ACCOUNTING_START_PROCESS';
export const ACCOUNTING_THREAD_CURRENT_GET = 'ACCOUNTING_THREAD_CURRENT_GET';
export const STORE_ACCOUNTING_THREAD_CURRENT = 'STORE_ACCOUNTING_THREAD_CURRENT';

export const XERO_GET_ACCOUNTS = 'XERO_GET_ACCOUNTS';
export const XERO_PUT_ACCOUNTS = 'XERO_PUT_ACCOUNTS';
export const REAP_GET_ACCOUNTS = 'REAP_GET_ACCOUNTS';
export const REAP_PUT_ACCOUNTS = 'REAP_PUT_ACCOUNTS';
export const XERO_REAP_MAPPING_POST = 'XERO_REAP_MAPPING_POST';
export const XERO_REAP_MAPPING_PUT = 'XERO_REAP_MAPPING_PUT';
export const XERO_REAP_MAPPING_GET = 'XERO_REAP_MAPPING_GET';
export const XERO_REAP_MAPPING_COMPLETION_PUT = 'XERO_REAP_MAPPING_COMPLETION_PUT'
export const XERO_REAP_MAPPING_UPDATE_DIV = 'XERO_REAP_MAPPING_UPDATE_DIV';

export const ACCOUNTING_THREAD_COMMIT_MAP = 'ACCOUNTING_THREAD_COMMIT_MAP';
export const ACCOUNTING_PERIOD_SCHEDULE = 'ACCOUNTING_PERIOD_SCHEDULE';
export const ACCOUNTING_PERIOD_SCHEDULE_MONTH = 'ACCOUNTING_PERIOD_SCHEDULE_MONTH';
export const ACCOUNTING_PERIOD_SCHEDULE_YEAR = 'ACCOUNTING_PERIOD_SCHEDULE_YEAR';
export const XERO_PULLS_GET = 'XERO_PULLS_GET';
export const XERO_PULL_GET = 'XERO_PULL_GET';
export const XERO_PULLS_STORE = 'XERO_PULLS_STORE';
export const XERO_PULLS_STORE_COUNT = 'XERO_PULLS_STORE_COUNT';
export const XERO_PALS_GET = 'XERO_PALS_GET';
export const XERO_PALS_STORE = 'XERO_PALS_STORE';
export const XERO_PALS_UPDATE_CHILDREN = 'XERO_PALS_UPDATE_CHILDREN';
export const XERO_ACCOUNTS_CHILD_ACCOUNT_POST = 'XERO_ACCOUNTS_CHILD_ACCOUNT_POST';
export const XERO_PAL_INVERT = 'XERO_PAL_INVERT';

export const ACC_PAL_ADJUSTMENT_ADD = 'ACC_PAL_ADJUSTMENT_ADD';
export const ACC_PAL_ADJUSTMENT_REMOVE = 'ACC_PAL_ADJUSTMENT_REMOVE';
export const ACC_PAL_ADJUSTMENT_STORE = 'ACC_PAL_ADJUSTMENT_STORE';
export const ACC_PAL_ADJUSTMENT_UNSTORE = 'ACC_PAL_ADJUSTMENT_UNSTORE';
export const ACC_PAL_ADJUSTMENTS_GET = 'ACC_PAL_ADJUSTMENTS_GET';
export const ACC_PAL_JOINED_GET = 'ACC_PAL_JOINED_GET';
export const ACC_PAL_JOINED_STORE = 'ACC_PAL_JOINED_STORE';
export const ACC_REAP_DIVISIONAL_SPLITS_GET = 'ACC_REAP_DIVISIONAL_SPLITS_GET';
export const ACC_REAP_DIVISIONAL_SPLITS_STORE = 'ACC_REAP_DIVISIONAL_SPLITS_STORE';
export const ACC_REAP_DIVISIONAL_SPLITS_UPDATE = 'ACC_REAP_DIVISIONAL_SPLITS_UPDATE';
export const ACC_PAL_GET_PRO_RATA_SPLIT = 'ACC_PAL_GET_PRO_RATA_SPLIT';
export const ACC_PAL_GET_PRO_RATA_SPLIT_STORE = 'ACC_PAL_GET_PRO_RATA_SPLIT_STORE';
export const ACC_PAL_GET_PRO_RATA_SPLITS_STORE = 'ACC_PAL_GET_PRO_RATA_SPLITS_STORE';
export const ACC_SPLIT_TEMPLATE_CREATE = 'ACC_SPLIT_TEMPLATE_CREATE';
export const ACC_SPLIT_TEMPLATE_STORE = 'ACC_SPLIT_TEMPLATE_STORE';
export const ACC_SPLIT_TEMPLATES_GET = 'ACC_SPLIT_TEMPLATES_GET';
export const ACC_SPLIT_TEMPLATE_LINES_GET = 'ACC_SPLIT_TEMPLATE_LINES_GET';
export const ACC_SPLIT_TEMPLATE_LINES_STORE = 'ACC_SPLIT_TEMPLATE_LINES_STORE';
export const ACC_SPLIT_TEMPLATE_APPLY = 'ACC_SPLIT_TEMPLATE_APPLY';
export const ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE = 'ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE';

export const REPORT_FINANCIAL_YEARS_GET = 'REPORT_FINANCIAL_YEARS_GET';
export const REPORT_FINANCIAL_YEARS_STORE = 'REPORT_FINANCIAL_YEARS_STORE';
export const REPORT_FIN_YEAR_PALS_GET = 'REPORT_FIN_YEAR_PALS_GET';
export const REPORT_FIN_YEAR_HAS_DATA_GET = 'REPORT_FIN_YEAR_HAS_DATA_GET';
export const REPORT_FIN_YEAR_HAS_DATA_STORE = 'REPORT_FIN_YEAR_HAS_DATA_STORE';
export const BACKGROUND_TASKS_GET = 'BACKGROUND_TASKS_GET';
export const BACKGROUND_TASKS_STORE = 'BACKGROUND_TASKS_STORE';
export const BACKGROUND_TASKS_STORE_COUNT = 'BACKGROUND_TASKS_STORE_COUNT';
