import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
  action,
  REAP_GET_ACCOUNTS,
  REAP_PUT_ACCOUNTS
} from './actions.js';

const newAction = action;

function* getReapAccountsSaga() {
  const getReapAccounts = async () => {
    const url = '/accounting/reap-accounts';
    const res = await axios.get(url);
    return res.data;
  }

  const reapAccounts = yield call(getReapAccounts);
  yield put(newAction(REAP_PUT_ACCOUNTS, reapAccounts));
}

export default function* reapSagas() {
  yield takeLatest(REAP_GET_ACCOUNTS, getReapAccountsSaga);
}
