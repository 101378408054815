import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import {
  action,
  signal,
  SIGNAL_EVENT,
  GET_CONNECTIONS,
  STORE_CONNECTIONS,
  GET_TENANTS,
  STORE_TENANTS,
  UPSERT_TENANTS,
  XERO_REAP_MAPPING_POST,
  XERO_REAP_MAPPING_PUT,
  XERO_REAP_MAPPING_GET,
  XERO_REAP_MAPPING_COMPLETION_PUT,
  XERO_PULLS_GET,
  XERO_PULL_GET,
  XERO_PULLS_STORE,
  XERO_PULLS_STORE_COUNT,
  XERO_PALS_GET,
  XERO_PALS_STORE,
  XERO_PAL_INVERT,
  XERO_REAP_MAPPING_UPDATE_DIV
} from './actions.js';

const newAction = action;

function* getConnectionsSaga(action) {
  const getConnections = async (payload) => {
    const params = new URLSearchParams(payload).toString();
    const url = `/xero/get-connections?${params}`;
    const res = await axios.get(url);
    return res.data;
  };

  try {
    const connections = yield call(getConnections, action.payload);
    yield put(newAction(STORE_CONNECTIONS, connections));
  } catch (err) {
    if (err.response.status !== 404) {
      throw err;
    }
  }
};

function* getTenantsSaga(action) {
  const getTenants = async (payload) => {
    const params = new URLSearchParams(payload).toString();
    const url = `/xero/get-tenants?${params}`;
    const res = await axios.get(url);
    return res.data;
  };

  try {
    const tenants = yield call(getTenants, action.payload);
    yield put(newAction(STORE_TENANTS, tenants));
  } catch (err) {
    if (err.response.status !== 404) { // 404 is expected
      throw err;
    }
  }
}

function* setTenantsSaga(action) {
  const setTenants = async (postBody) => {
    const res = await axios.post('/xero/set-tenants', postBody);
    return res.data;
  };

  const tenants = yield call(setTenants, action.payload);
  yield put(newAction(STORE_TENANTS, tenants));
  yield put(newAction(SIGNAL_EVENT, signal('tenants-saved', action.payload.officeId)));
}

function* setXeroReapMappingSaga(action) {
  const setXeroReapMapping = async (postBody) => {
    const res = await axios.post('/accounting/xero-reap-mapping', postBody);
    const mappingComplete = res.headers['x-ri-mapping-complete'];
    return [res.data, mappingComplete === 'true'];
  };

  const [mapping, mappingComplete] = yield call(setXeroReapMapping, action.payload);

  yield put(newAction(XERO_REAP_MAPPING_PUT, [mapping]));
  const completion = { id: action.payload.officeId, mappingComplete: mappingComplete }
  yield put(newAction(XERO_REAP_MAPPING_COMPLETION_PUT, [completion]));
}

function* getXeroReapMappingsSaga(action) {
  const getXeroReapMappings = async(officeId) => {
    const url = `/accounting/xero-reap-mappings?officeId=${officeId}`;
    const res = await axios.get(url);
    const mappingComplete = res.headers['x-ri-mapping-complete'];
    return [res.data, mappingComplete === 'true'];
  };

  const [mappings, mappingComplete] = yield call(getXeroReapMappings, action.payload);
  yield put(newAction(XERO_REAP_MAPPING_PUT, mappings));

  const completion = { id: action.payload, mappingComplete: mappingComplete }
  yield put(newAction(XERO_REAP_MAPPING_COMPLETION_PUT, [completion]));
};

export function* getXeroPullsSaga(action) {
  const getXeroPulls = async (params) => {
    try {
      const res = await axios.get('/xero/get-account-pulls', { params });
      const count = res.headers['x-ri-count'];
      return [res.data, count];
    }
    catch (err) {
    }
  };

  const [pulls, count] = yield call(getXeroPulls, action.payload);
  yield put(newAction(XERO_PULLS_STORE, pulls));
  yield put(newAction(XERO_PULLS_STORE_COUNT, count));
};

export function* getXeroPullSaga(action) {
  const getXeroPull = async (params) => {
    try {
      const res = await axios.get('/xero/get-account-pull', { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const pull = yield call(getXeroPull, action.payload);
  yield put(newAction(XERO_PULLS_STORE, [pull]));
};

export function* getXeroPalSaga(action) {
  const getXeroPal = async (params) => {
    try {
      const res = await axios.get('/xero/get-account-profit-and-loss', { params });
      return res.data;
    }
    catch (err) {
    }
  };

  const pal = yield call(getXeroPal, action.payload);
  yield put(newAction(XERO_PALS_STORE, pal));
};

function* xeroReapMapUpdateDivisionSaga(action) {
  const accXeroReapMapUpdateDivision = async (postBody) => {
    const url = `/accounting/xero-reap-mapping-division`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  const updatedMapping = yield call(accXeroReapMapUpdateDivision, action.payload);
  yield put(newAction(XERO_REAP_MAPPING_PUT, [updatedMapping]));
}

function* xeroPalInvertSaga(action) {
  const xeroPalInvert = async (postBody) => {
    const url = `/xero/profit-and-loss-invert`;
    const res = await axios.post(url, postBody);
    return res.data;
  };

  const updatedPal = yield call(xeroPalInvert, action.payload);
  yield put(newAction(XERO_PALS_STORE, [updatedPal]));
}



export default function* xeroSagas() {
  yield takeLatest(UPSERT_TENANTS, setTenantsSaga);
  yield takeLatest(GET_CONNECTIONS, getConnectionsSaga);
  yield takeLatest(GET_TENANTS, getTenantsSaga);
  yield takeLatest(XERO_REAP_MAPPING_POST, setXeroReapMappingSaga);
  yield takeLatest(XERO_REAP_MAPPING_GET, getXeroReapMappingsSaga);
  yield takeLatest(XERO_PULLS_GET, getXeroPullsSaga);
  yield takeLatest(XERO_PULL_GET, getXeroPullSaga);
  yield takeLatest(XERO_PALS_GET, getXeroPalSaga);
  yield takeLatest(XERO_REAP_MAPPING_UPDATE_DIV, xeroReapMapUpdateDivisionSaga);
  yield takeLatest(XERO_PAL_INVERT, xeroPalInvertSaga);
}
