import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { Layout, Menu } from 'antd';
import { PlusSquareOutlined,
         UnorderedListOutlined,
         HomeOutlined,
         SettingOutlined,
         LogoutOutlined,
         DownOutlined,
         RetweetOutlined,
         ProfileOutlined } from '@ant-design/icons';
import { ROLE_ROOT, ROLE_USER, ROLE_ADMIN } from '../../const.js';
import { ROUTE_LOGIN, ROUTE_SETTINGS, router } from '../../AppRoutes.js';
import { WRITE_JWT_TO_LOCAL_STORE, action } from '../../state/actions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const AppAuthenticated = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const jwt = useSelector(state => state.accessControl.jwt);
  const [appMenu, setAppMenu] = useState([]);

  useEffect(() => {
    setAppMenu(generateApplicationMenu());
  }, [jwt]);

  var jwtDecoded = jwt_decode(jwt);

  const onClickSignout = () => {
    window.localStorage.removeItem('jwt');
    dispatch(action(WRITE_JWT_TO_LOCAL_STORE, ''));

    navigate(ROUTE_LOGIN);
  };

  const onClickSettings = () => {
    navigate(ROUTE_SETTINGS);
  };

  const onClickApplicationMenu = (item) => {
    switch (item.key) {
      case 'add-office':
        router.navigate('/office/add');
        break;
      case 'view-offices':
        router.navigate('/office/overview');
        break;
      case 'financial-year':
        router.navigate('/reports/financial-year');
        break;
      case 'overview-background-tasks':
        router.navigate('/background-tasks/overview');
        break;
    };
  };

  const userMenu = [
    {
      label: `${jwtDecoded.first_name} ${jwtDecoded.last_name}`,
      key: 'user-menu',
      icon: <DownOutlined />,
      children: [
        {
          label: 'Settings',
          key: 'settings',
          onClick: onClickSettings,
          icon: <SettingOutlined />
        },
        {
          label: 'Sign Out',
          key: 'sign-out',
          onClick: onClickSignout,
          icon: <LogoutOutlined />
        },
      ],
    }
  ];

  const addMenuItemForRoles = (roles, menuItem) => {
    if (roles.some(r => r === jwtDecoded.role)) {
      return menuItem;
    }

    return null;
  };

  const generateApplicationMenu = () => {
    const menu = [];

    const roleUserMenu = [
      {
        label: `Offices`,
        key: 'offices-menu',
        icon: <HomeOutlined />,
        children: [
          addMenuItemForRoles([ROLE_USER, ROLE_ADMIN, ROLE_ROOT], {
            label: 'Add Office',
            key: 'add-office',
            icon: <PlusSquareOutlined />,
          }),
          {
            label: 'View Offices',
            key: 'view-offices',
            icon: <UnorderedListOutlined />
          },
        ],
      },
      addMenuItemForRoles([ROLE_ADMIN, ROLE_ROOT], {
        label: `Tasks`,
        key: 'background-tasks-menu',
        icon: <RetweetOutlined />,
        children: [
          {
            label: 'Overview',
            key: 'overview-background-tasks',
            icon: <ProfileOutlined />,
          }
        ]
      }),
    ];

    menu.push(...roleUserMenu);

    return menu;
  };

  return (
    <Layout style={{height:'100vh'}}>
      <Header style={{ height: '60px' }}>
        <div style={{dispay: 'block', height: '100px', width: '50px', float: 'left'}}>
          <Link to='/'>
            <img alt="REAP" src={require('./reap-logo.png')}
              style={{
                width: '28px',
                height: '28px',
                marginTop: '10px'
              }} />
          </Link>
        </div>
        <div style={{ float: "right", display: "block", width: "fit-content" }}>
          <Menu theme="dark"
            mode="horizontal"
            disabledOverflow="true"
            selectable={false}
            items={userMenu} />
        </div>
      </Header>
      <Layout
        style={{
          backgroundColor: '#001529'
        }}>
        <Sider
          width={200}
        >
          <Menu
            theme="dark"
            mode="inline"
            onClick={onClickApplicationMenu}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={appMenu}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
            backgroundColor: '#ffffff'
          }}
        >
          <Content
            style={{
              marginTop: 24,
              padding: 24,
              margin: 0,
              minHeight: 280,
              overflowY: 'scroll'
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppAuthenticated;
