const countries = [
  {
    code: 'AUS',
    country: 'Australia',
    phonePrefix: '61',
    states: [
      { state: 'New South Wales', code: 'NSW' },
      { state: 'Victoria', code: 'VIC' },
      { state: 'Queensland', code: 'QLD' },
      { state: 'Western Australia', code: 'WA' },
      { state: 'South Australia', code: 'SA' },
      { state: 'Tasmania', code: 'TAS' }
    ]
  },
  {
    code: 'NZ',
    country: 'New Zealand',
    phonePrefix: '64',
    states: [
      { state: 'New South Wales', code: 'NSW' },
      { state: 'Northland', code: 'NZ-NTL' },
      { state: 'Auckland', code: 'NZ-AUK' },
      { state: 'Waikato', code: 'NZ-WKO' },
      { state: 'Bay of Plenty', code: 'NZ-BOP' },
      { state: 'Gisborne', code: 'NZ-GIS' },
      { state: `Hawke's Bay`, code: 'NZ-HKB' },
      { state: 'Taranaki', code: 'NZ-TKI' },
      { state: 'Manawatū', code: -'Whanganui NZ-MWT' },
      { state: 'Wellington', code: 'NZ-WGN' },
      { state: 'Tasman', code: 'NZ-TAS' },
      { state: 'Nelson', code: 'NZ-NSN' },
      { state: 'Marlborough', code: 'NZ-MBH' },
      { state: 'West', code: 'Coast NZ-WTC' },
      { state: 'Canterbury', code: 'NZ-CAN' },
      { state: 'Otago', code: 'NZ-OTA' },
      { state: 'Southland', code: 'NZ-STL' }
    ]
  }
];

const getStateFromCode = (countryCode, stateCode) => {
  const c = countries.find(c => c.code === countryCode);
  const s = c.states.find(s => s.code === stateCode);
  return s.state;
};

const getCountryFromCode = (countryCode) => {
  const c = countries.find(c => c.code === countryCode);
  return c.country;
};

export default countries;

export {
  getStateFromCode,
  getCountryFromCode
};
