import React from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column'
    }}>
      <div style={{ marginTop: '200px' }}>
        <DownloadOutlined style={{ fontSize: '3em' }} />
      </div>
      <div style={{ marginTop: '100px' }}>
         <Spin tip="Loading..." />
      </div>
    </div>

  );
};

export default Loading;
