import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Button, Typography, Popconfirm, Spin, Checkbox } from 'antd';
import {
  action,
  signalMatch,
  STORE_XERO_AUTH_URL,
  GET_XERO_AUTH_URL,
  STORE_XERO_AUTH_STATE,
  CHECK_TOKEN_EXISTS,
  DELETE_TOKEN,
  GET_CONNECTIONS,
  GET_TENANTS,
  UPSERT_TENANTS
} from '../../state/actions.js';
import jwt_decode from "jwt-decode";
import './Checkbox.css';

const XeroIntegration = (props) => {
  const { Title } = Typography;

  const dispatch = useDispatch();

  // Selectors
  const xeroAuthUrl = useSelector(state => state.xero.authUrl);;
  const jwt = useSelector(state => state.accessControl.jwt);
  const tokenExists = useSelector(state => state.tokens.tokenExists);
  const connections = useSelector(state => state.xero.connections);
  const tenants = useSelector(state => state.xero.tenants);
  const signal = useSelector(state => state.signal);

  // State
  const [open, setOpen] = useState(false);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [savingTenants, setSavingTenants] = useState(false);

  // Effects
  useEffect(() => {
    dispatch(action(STORE_XERO_AUTH_URL, ''));
    dispatch(action(STORE_XERO_AUTH_STATE, ''));
    dispatch(action(GET_XERO_AUTH_URL, props.officeId));
    dispatch(action(CHECK_TOKEN_EXISTS, {
      officeId: props.officeId,
      application: 'xero'
    }));
    dispatch(action(GET_CONNECTIONS, { officeId: props.officeId }));
    dispatch(action(GET_TENANTS, { officeId: props.officeId }));
  }, [dispatch, props.officeId]);

  useEffect(() => {
    if (signalMatch(signal, 'tenants-saved', props.officeId)) {
      setTimeout(() => {
        setSavingTenants(false);
      }, 500); // In case of very fast connection
    }
  }, [signal]);


  useEffect(() => {
    const checkedTenants = officeConnections.map(c => {
      if (officeTenants.some(t => t.tenant_id === c.tenantId)) {
        return c.tenantId;
      }
    });
    setSelectedTenants(checkedTenants);
  }, [connections]);


  // Derivatives
  const xeroDisabled = (!xeroAuthUrl === true);
  const jwtDecoded = jwt_decode(jwt);
  const xeroTokenExists = tokenExists.find(t => {
    return (t.userId === jwtDecoded.id &&
            t.officeId === props.officeId &&
            t.application === 'xero');
  });
  const officeConnections = connections.filter(c => c.officeId === props.officeId);
  const officeTenants = tenants.filter(t => t.office_id === props.officeId);
  const tenantOptions = officeConnections.map(c => ({ label: c.tenantName, value: c.tenantId }));
  const tenantsLoading = tenantOptions.length === 0;

  if (!xeroTokenExists) {
    return null;
  }

  const xeroAttachButtonText = xeroTokenExists.exists ? 'Change XERO Authorization' : 'Authorize XERO Account';

  // Event Handlers
  const xeroAttach = () => {
    window.location.href = xeroAuthUrl;
  };

  const xeroRevoke = () => {
    dispatch(action(DELETE_TOKEN, { id : xeroTokenExists.tokenId,
                                    officeId: xeroTokenExists.officeId,
                                    application: 'xero' }));
  };

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = () => {
    xeroRevoke();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onChangeTenantSelection = (tenants) => {
    setSelectedTenants(tenants);
  };

  const onSaveTenantSelection = () => {
    setSavingTenants(true);
    const tenants = tenantOptions.filter(to => selectedTenants.some(st => st === to.value));
    dispatch(action(UPSERT_TENANTS, { officeId: props.officeId, tenants: tenants }));
  };

  // Render
  const renderConnectionsCheckboxes = () => {
    return (
      <div>
        {tenantsLoading &&
          < Spin style={{ marginTop: '20px' }} />
        }

        {!tenantsLoading &&
          <div style={{ marginTop: '60px' }}>
            <p>Pick XERO tenants to use with this office.</p>
            <Checkbox.Group
              onChange={onChangeTenantSelection}
              options={tenantOptions}
              value={selectedTenants}>
            </Checkbox.Group>
            <Button style={{ marginTop: '40px' }} type="primary" loading={savingTenants} onClick={onSaveTenantSelection}>Save Tenants</Button>
          </div>
        }
      </div>
    );
  };

  const renderXeroTokenExists = () => {
    return (
        <div style={{ marginTop: '20px' }}>
        <p>You have attached a XERO Account to be used with this office.</p>
        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
          <Popconfirm
            title="Revoke Authorization"
            description="Revoking authorization will halt all REAP Dashboard updates."
            open={open}
            onConfirm={handleOk}
            onCancel={handleCancel}
          >
            <Button type="primary" danger onClick={showPopconfirm}>Revoke Authorization</Button>
          </Popconfirm>


          <Button type="primary"
            disabled={xeroDisabled}
            loading={xeroDisabled}
            onClick={xeroAttach}>{xeroAttachButtonText}</Button>
        </div>
      </div>
    );
  };

  const renderXeroTokenDoesNotExist = () => {
    return (
      <div>
        <p>Attach a XERO account to the REAP dashboard.</p>
        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
          <Button type="primary"
            disabled={xeroDisabled}
            loading={xeroDisabled}
            onClick={xeroAttach}>{xeroAttachButtonText}</Button>
        </div>
      </div>
    );
  };

  return (
    <Row gutter={64} style={{ marginTop: '64px' }}>
      <Col span={8}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <img alt="Attach XERO Account." src={require('./xero-icon.png')}
            style={{ width: '24px', height: '24px' }} />
          <Title level={4} style={{ marginTop: '10px', marginLeft: '10px' }}>XERO Account</Title>
        </div>
        {xeroTokenExists.exists &&
         renderXeroTokenExists()
        }

        <div style={{marginTop: '10px'}} />

        {xeroTokenExists.exists &&
         renderConnectionsCheckboxes()
        }

        {!xeroTokenExists.exists &&
         renderXeroTokenDoesNotExist()
        }
      </Col>
    </Row>

  );
};

export default XeroIntegration;
