import React, { useEffect, useState } from 'react';
import { Typography, Button, Tooltip, Modal, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { userIs } from '../../utils/jwt.js';
import { ROLE_ADMIN, ROLE_ROOT } from '../../const.js';
import PermissionError from '../Error/PermissionError.js';
import { action,
         XERO_GET_ACCOUNTS,
         GET_TENANTS,
         REAP_GET_ACCOUNTS,
         XERO_REAP_MAPPING_POST,
         XERO_REAP_MAPPING_GET,
         ACCOUNTING_THREAD_COMMIT_MAP,
         XERO_ACCOUNTS_CHILD_ACCOUNT_POST,
         XERO_REAP_MAPPING_UPDATE_DIV
       } from '../../state/actions.js';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { Table, Select, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ROUTE_OFFICE_VIEW } from '../../AppRoutes.js';
import { BranchesOutlined } from '@ant-design/icons';
import { copy } from '../../utils/data.js';

const { Option } = Select;

const MapAccounts = () => {
  const { TextArea } = Input;

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  const officeId = searchParams.get("officeId");

  useEffect(() => {
    dispatch(action(XERO_REAP_MAPPING_GET, officeId));
    dispatch(action(XERO_GET_ACCOUNTS, officeId));
    dispatch(action(GET_TENANTS, { officeId: officeId }));
    dispatch(action(REAP_GET_ACCOUNTS));
  }, [dispatch, officeId]);

  const jwt = useSelector(state => state.accessControl.jwt);
  const tenants = useSelector(state => state.xero.tenants)
        .filter(t => t.office_id === officeId);

  const mappings = useSelector(state => state.reap.mappings)
        .filter(m => m.office_id === officeId);

  const xa = copy(useSelector(state => state.xero.accounts));
  const xaCode = xa.filter(i => i.code !== null)
        .sort((a, b) => a.code.localeCompare(b.code));
  const xaNoCode = xa.filter(i => i.code === null)
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((item, index) => {
          return Object.assign(item, {
            code: `NOCODE${index}`
          });
        });
  const xeroAccounts = [...xaCode, ...xaNoCode];


  const reapAccounts = useSelector(state => state.reap.accounts);

  const completion = useSelector(state => state.reap.mappingCompletions)
        .filter(c => c.id === officeId)
        .shift();

  const [modalVisible, setModalVisible] = useState(false);
  const [parent, setParent] = useState(null);

  if (! ( userIs(jwt, ROLE_ADMIN) ||
          userIs(jwt, ROLE_ROOT) ) ) {
    return (
      <PermissionError/>
    );
  }

  const renderModal = () => {
    const onOk = async () => {
      try {
        const values = await form.validateFields();
        setModalVisible(false);
        form.resetFields();
        Object.assign(values, {
          parentId: parent.id,
          officeId: officeId
        });

        setParent(null);

        dispatch(action(XERO_ACCOUNTS_CHILD_ACCOUNT_POST, values));
      }
      catch {
      }
    };

    const onCancel = () => {
      setModalVisible(false);
    }

    return (
      <Modal title="Create new Child Account" open={modalVisible} onOk={onOk} onCancel={onCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onOk}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please provide a name for the new child account row.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please provide a short description for the new child account row.',
              },
            ]}
          >
            <TextArea rows={4}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const onMappingChanged = (value) => {
    const mapping = JSON.parse(value);
    dispatch(action(XERO_REAP_MAPPING_POST, mapping));
  };

  const onClickBranchAccount = (record) => {
    setParent(record);
    setModalVisible(true);
  };

  const onDivisionChanged = (value) => {
    const division = JSON.parse(value);
    dispatch(action(XERO_REAP_MAPPING_UPDATE_DIV, division));
  };

  const generateMapTable = (title, xeroAccounts, reapAccounts, color) => {
    const columns = [
      {
        title: '',
        width: 50,
        render: (_, record) => {
          if (record.parent !== null) {
            return null;
          }
          return (
            <Tooltip title="Split Account">
              <Button shape="circle" icon={<BranchesOutlined />}
                      onClick={() => { onClickBranchAccount(record); }}/>
            </Tooltip>
          );
        }
      },
      {
        title: 'Code', width: 100, dataIndex: 'code', key: 'code', render: (code, record) => {
          return (
            <Tag color={color}>{code}</Tag>
          );
        }
      },
      { title: 'Name', width: 300, dataIndex: 'name', key: 'name' },
      { title: 'Description', width: 500, dataIndex: 'description', key: 'description' },
      {
        title: 'Mapped To',
        dataIndex: 'mappedTo',
        key: 'mappedTo',
        width: 400,
        render: (_, record) => {
          const mapping = mappings.find(m => m.xero_account_id === record.id);

          const defaultId = mapping ? mapping.reap_account_id : '';

          const defaultAccount = reapAccounts.find(a => a.id === defaultId);

          const defaultValue = (defaultAccount === undefined) ? '' : defaultAccount.name;

          const key = `select-${defaultValue}-${record.id}`; // Force rerender when default value changes

          return (
            <Select key={key}
                    showSearch
                    defaultValue={defaultValue}
                    style = {{ width: 300 }}
                    onChange = { onMappingChanged }
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().includes(input.toLowerCase());
                    }}>
              {
                reapAccounts.map(option => {
                  const value = JSON.stringify({
                    officeId: record.office_id,
                    tenantId: record.tenant_id,
                    xeroAccountId: record.id,
                    reapAccountId: option.id
                  });
                  return (
                    <Option key={option.id} value={value} label={option.name}>{option.name}</Option>
                  )
                })
              }
            </Select >
          );
        },
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: 100,
        render: (_, record) => {
          const key = `select-division-${record.id}`;

          const mapping = mappings.find(m => m.xero_account_id === record.id);

          if (mapping === undefined) {
            return null;
          }

          if (record.type !== 'REVENUE') {
            return null;
          }

          const id = mapping.id;

          function toDisplay(division) {
            switch (division) {
              case 'sales':
                return 'Sales';
              case 'pm':
                return 'PM';
              case 'dir':
                return 'Dir';
            }
          }

          const divisions = [
            {id, division: 'sales'},
            {id, division: 'pm'},
            {id, division: 'dir'}
          ];

          return (
            <Select key={key} defaultValue={toDisplay(mapping.division)} style={{ width: 300 }} onChange={onDivisionChanged} >
              {
                divisions.map(division => {
                  const value = JSON.stringify(division);
                  return (
                    <Option key={division.division} value={value}>{toDisplay(division.division)}</Option>
                  )
                })
              }
            </Select >
          );
        },
      },
      {
        dataIndex: 'saved',
        key: 'saved',
        render: (_, record) => {
          const saved = mappings.some(m => m.xero_account_id === record.id);
          if (saved) {
            return (
              <span>
                <CheckCircleOutlined style={{ color: '#03c03c', cursor: 'pointer' }} />
              </span>
            );
          } else {
            return null;
          }
        }
      }
    ];

    return (
      <div>
        <Table
          dataSource={xeroAccounts}
          rowKey="id"
          columns={columns}
          pagination={false}
          title={() => <Typography.Title level={5}>{title}</Typography.Title>}
        />
      </div>
    );
  };

  const renderTenantMaps = (tenant) => {
    const revenue = xeroAccounts.filter(a => a.tenant_id === tenant.tenant_id &&
                                        a.type === 'REVENUE');

    const other = xeroAccounts.filter(a => a.tenant_id === tenant.tenant_id &&
                                      a.type === 'OTHERINCOME');

    const expense = xeroAccounts.filter(a => a.tenant_id === tenant.tenant_id &&
                                        a.type === 'EXPENSE');

    const directCosts = xeroAccounts.filter(a => a.tenant_id === tenant.tenant_id &&
                                            a.type === 'DIRECTCOSTS');

    return (
      <div key={tenant.id}>
        <Typography.Title level={4}>{tenant.tenant_name}</Typography.Title>
        { generateMapTable('Revenue', revenue, reapAccounts, 'green') }
        { generateMapTable('Other Income', other, reapAccounts, 'blue') }
        { generateMapTable('Expense', expense, reapAccounts, 'red') }
        { generateMapTable('Direct Costs', directCosts, reapAccounts, 'orange') }
      </div>
    );
  };

  const commitMappings = () => {
    dispatch(action(ACCOUNTING_THREAD_COMMIT_MAP, { officeId }));

    const params = {
      id: officeId
    };

    navigate({
      pathname: ROUTE_OFFICE_VIEW,
      search: createSearchParams(params).toString()
    });
  };

  const renderCompletion = () => {
    if (completion === undefined) {
      return null;
    }

    if (completion.mappingComplete) {
      return (
        <div>
          <Button type="primary" onClick={commitMappings}>Commit Mappings</Button>
        </div>
      );
    }

    if (!completion.mappingComplete) {
      return (
        <div>
          <Typography.Title level={4}>Mapping Incomplete</Typography.Title>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography.Title level={3}>Map Accounts</Typography.Title>
      {renderCompletion()}
      {tenants.map((tenant) => renderTenantMaps(tenant))}
      {renderModal()}
    </div>
  );
};

export default MapAccounts;
