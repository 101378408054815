import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  action,
  GET_XERO_AUTH_URL
} from '../../state/actions.js';

const LoginXero = () => {

  const dispatch = useDispatch();

  const xeroAuthUrl = useSelector(state => state.xero.authUrl);;

  useEffect(() => {
    dispatch(action(GET_XERO_AUTH_URL, 'login'));
  }, [dispatch]);

  const xeroDisabled = (!xeroAuthUrl === true);

  if (xeroDisabled) {
    return (
      <Tooltip title="Checking XERO Sign In.">
        <a target="_self">
          <img alt="Sign In with Microsoft." src={require('./login-xero-disabled.png')}
            style={{ width: '48px', height: '48px' }} />
        </a>
      </Tooltip>
    );
  }
  else {
    return (
      <Tooltip title="Sign In with XERO">
        <a href={xeroAuthUrl} target="_self">
          <img alt="Sign In with XERO." src={require('./login-xero.png')}
            style={{ width: '48px', height: '48px' }} />
        </a>
      </Tooltip>
    );
  }
};

export default LoginXero;
