import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import InfoCard from '../InfoCard/InfoCard.js';
import { action,
         READ_AUTH_STATE,
         AUTHORIZE_MICROSOFT } from '../../state/actions.js';
import { ROUTE_HOME } from '../../AppRoutes.js';
import { jwtValid } from "../../utils/jwt.js";
import { Link } from "react-router-dom";

const AuthorizeMicrosoft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(action(READ_AUTH_STATE));
  }, [dispatch]);

  const state = useSelector(state => state.accessControl.authState);
  const jwt = useSelector(state => state.accessControl.jwt);

  const redirectResponse = {
    code : searchParams.get('code')
  };

  const redirectState = searchParams.get('state');
  const adminConsent = searchParams.get('admin_consent');

  useEffect(() => {
    if (jwtValid(jwt)) {
      navigate(ROUTE_HOME);
    }
  }, [jwt, navigate]);

  useEffect(() => {
    if (!jwtValid(jwt)) {
      dispatch(action(AUTHORIZE_MICROSOFT, redirectResponse));
    }
  }, []);

  if (state === '') {
    return null;
  }

  if (adminConsent === 'True') {
    return (
      <InfoCard>
        <p>Admin Consent has granted. Please <Link to='/login'>log on</Link> with a Microsoft account from the domain.</p>
      </InfoCard>
    );
  }

  if (state !== redirectState) {
    return (
      <InfoCard>
        <p>We've encountered a problem logging you in. Please contact support.</p>
      </InfoCard>
    );
  }

  return (
    <InfoCard>
      <Spin tip="Authorizing with Microsoft" size="large">
      </Spin>
    </InfoCard>
  );
};

export default AuthorizeMicrosoft;
