import React, { useState } from 'react';
import { Button, Menu, Popover, Tooltip } from 'antd';
import { PlusOutlined,
         ArrowRightOutlined,
         ContainerOutlined,
         PercentageOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';
import { withoutId } from '../../utils/data.js';
import CompoundSplitRowPercentage from './CompoundSplitRowPercentage.js';
import CompoundSplitRowAccountProRata from './CompoundSplitRowAccountProRata.js';

function CompoundSplitRowMenu(props) {
  const [open, setOpen] = useState(false);

  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(250);

  function handleClick(e) {
    const split_type = e.key;
    const split = props.row.split;
    const meta = JSON.parse(split.split_meta);
    const split_index = meta.length;

    if (split_type === 'percentage') {
      meta.push({
        split_index,
        split_type,
        split_meta: JSON.stringify({
          sales: 0,
          pm: 0,
          dir: 0
        })
      });
    } else if (split_type === 'account-pro-rata') {
      meta.push({
        split_index,
        split_type,
        split_meta: JSON.stringify({
          sales: 'none',
          pm: 'none',
          dir: 'none'
        })
      });
    }

    split.split_meta = JSON.stringify(meta);
    split.updated = true;

    const newSplits = [...withoutId(props.model.splits, split.id), split];
    props.setSplitsState(newSplits);

    setOpen(false);
  }

  function updateSubSplit(subSplit) {
    const split = props.row.split;
    const meta = JSON.parse(split.split_meta);
    const newMeta = meta.filter(i => i.split_index !== subSplit.split_index);
    newMeta.push(subSplit);

    split.split_meta = JSON.stringify(newMeta);
    split.updated = true;

    const newSplits = [...withoutId(props.model.splits, split.id), split];
    props.setSplitsState(newSplits);
  }


  function renderPercentageControls() {
    return <CompoundSplitRowPercentage
             item={props.item}
             record={props.record}
             updateSubSplit={updateSubSplit}
           />
  }

  function renderAccountProRataControls() {
    return <CompoundSplitRowAccountProRata
             item={props.item}
             record={props.record}
             updateSubSplit={updateSubSplit}
             model={props.model}
           />
  }

  function renderNewSplitMenu() {
    return (
      <Menu mode="vertical">
        <Menu.Item key="percentage"
          icon={<PercentageOutlined />}
          onClick={handleClick}>
          Percentage Split
        </Menu.Item>
        <Menu.Item key="account-pro-rata"
          icon={<ContainerOutlined />}
          onClick={handleClick}>
          Account Based Pro-Rata
        </Menu.Item>
      </Menu>
    );
  }

  function renderPopeverContent() {
    let childContent = null;

    if (props.record.row_type === 'footer') {
      setHeight(100);
      setWidth(300);
      childContent = renderNewSplitMenu();
    } else if (props.record.row_type === 'subSplit') {
      if (props.record.split_type === 'percentage') {
        setHeight(120);
        setWidth(400);
        childContent = renderPercentageControls();
      } else if (props.record.split_type === 'account-pro-rata') {
        setHeight(200);
        setWidth(800);
        childContent = renderAccountProRataControls();
      }
    }

    return (
      <div style={{height: height, width: width}}>
        {childContent}
      </div>
    );
  }

  function onOpenChange(openState) {
    if (props.record.row_type === 'header') {
      return;
    }

    setOpen(openState);
  }

  function renderIcon() {
    if (props.record.row_type === 'header') {
      return <ArrowRightOutlined style={{ color: 'green' }} />
    }
    else if (props.record.row_type === 'footer') {
      return <PlusOutlined style={{ color: 'green' }} />
    } else if (props.record.row_type === 'subSplit') {
      if (props.record.split_type === 'percentage') {
        return <PercentageOutlined style={{ color: 'blue' }} />
      } else if (props.record.split_type === 'account-pro-rata') {
        return <ContainerOutlined style={{ color: 'blue' }} />
      }
    }
  }

  function getTooltipText() {
    if (props.record.row_type === 'header') {
      return "This line shows the total value for the account.";
    }
    else if (props.record.row_type === 'footer') {
      return "Add New Compound Split Line"
    }
    else if (props.record.row_type === 'subSplit') {
      if (props.record.split_type === 'percentage') {
        return 'Percentage Split'
      } else if (props.record.split_type === 'account-pro-rata') {
        return 'Account Based Pro Rata Split'
      }
    }

    return '';
  }

  return (
    <Tooltip title={getTooltipText()} key={props.row.id}>
      <Popover content={() => renderPopeverContent()}
        trigger="click"
        open={open}
        onOpenChange={onOpenChange}>
        <Button shape="circle" size="small"
          icon={renderIcon()} />
      </Popover>
    </Tooltip>
  );
}

export default CompoundSplitRowMenu;
