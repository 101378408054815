import { combineReducers } from 'redux';
import {
  READ_JWT_FROM_LOCAL_STORE,
  WRITE_JWT_TO_LOCAL_STORE,
  READ_AUTH_STATE,
  STORE_AUTH_STATE,
  WRITE_INVALID_PASSWORD_STATE,
  STORE_OFFICES,
  SIGNAL_EVENT,
  SIGNAL_CLEAR,
  STORE_OFFICE_COUNT,
  STORE_XERO_AUTH_STATE,
  READ_XERO_AUTH_STATE,
  STORE_XERO_AUTH_URL,
  STORE_TOKEN_EXISTS,
  UNSTORE_TOKEN_EXISTS,
  STORE_CONNECTIONS,
  STORE_TENANTS,
  STORE_OAS,
  STORE_ACCOUNTING_THREAD_CURRENT,
  XERO_PUT_ACCOUNTS,
  REAP_PUT_ACCOUNTS,
  XERO_REAP_MAPPING_PUT,
  XERO_REAP_MAPPING_COMPLETION_PUT,
  XERO_PULLS_STORE,
  XERO_PULLS_STORE_COUNT,
  XERO_PALS_STORE,
  ACC_PAL_ADJUSTMENT_STORE,
  ACC_PAL_ADJUSTMENT_UNSTORE,
  ACC_PAL_JOINED_STORE,
  ACC_REAP_DIVISIONAL_SPLITS_STORE,
  ACC_PAL_GET_PRO_RATA_SPLIT_STORE,
  ACC_PAL_GET_PRO_RATA_SPLITS_STORE,
  ACC_SPLIT_TEMPLATE_STORE,
  ACC_SPLIT_TEMPLATE_LINES_STORE,
  ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE,
  REPORT_FINANCIAL_YEARS_STORE,
  BACKGROUND_TASKS_STORE,
  BACKGROUND_TASKS_STORE_COUNT,
  REPORT_FIN_YEAR_HAS_DATA_STORE
} from './actions.js';
import initialState from './initialState.js';
import { uniqueById } from '../utils/data.js';

const accessControl = (state = initialState.accessControl, action) => {
  switch (action.type) {
    case READ_JWT_FROM_LOCAL_STORE:
      const jwt = window.localStorage.getItem('jwt');
      return { ...state, jwt };
    case WRITE_JWT_TO_LOCAL_STORE:
      window.localStorage.setItem('jwt', action.payload);
      return { ...state, jwt : action.payload };
    case STORE_AUTH_STATE:
      window.localStorage.setItem('microsoft-oauth-state', action.payload.state);
      return { ...state, authState: action.payload.state }
    case READ_AUTH_STATE:
      const authState = window.localStorage.getItem('microsoft-oauth-state');
      return { ...state, authState: authState }
    case WRITE_INVALID_PASSWORD_STATE:
      return { ...state, invalidPasswordState: action.payload }
    default:
      return state;
  };
};

const offices = (state = initialState.offices, action) => {
  switch (action.type) {
    case STORE_OFFICES:
      const newOffices = uniqueById([...action.payload, ...state.items]);
      return { ...state, items: newOffices };
    case STORE_OFFICE_COUNT:
      return { ...state, serverCount: action.payload }
    case STORE_OAS:
      const newOas = uniqueById([...action.payload, ...state.accountStatus]);
      return { ...state, accountStatus: newOas };
    default:
      return state;
  };
};

const signal = (state = initialState.signal, action) => {
  switch (action.type) {
    case SIGNAL_EVENT:
      return { ...state, ...action.payload }
    case SIGNAL_CLEAR:
      return null;
    default:
      return state;
  };
};

const xero = (state = initialState.xero, action) => {
  switch (action.type) {
    case STORE_XERO_AUTH_URL:
      return { ...state, authUrl: action.payload }
    case STORE_XERO_AUTH_STATE:
      window.localStorage.setItem('xero-oauth-state', action.payload);
      return { ...state, authState: action.payload }
    case READ_XERO_AUTH_STATE:
      const authState = window.localStorage.getItem('xero-oauth-state');
      return { ...state, authState: authState }
    case STORE_CONNECTIONS:
      const newConnections = uniqueById([...action.payload, ...state.connections]);
      return { ...state, connections: newConnections }
    case STORE_TENANTS:
      action.payload.forEach(t => t.id = `${t.office_id}.${t.tenant_id}`);
      const newTenants = [...action.payload];
      return { ...state, tenants: newTenants};
    case XERO_PUT_ACCOUNTS:
      const newAccounts = uniqueById([...action.payload, ...state.accounts]);
      return { ...state, accounts: newAccounts }
    case XERO_PULLS_STORE:
      const newPulls = uniqueById([...action.payload, ...state.accountPulls]);
      return { ...state, accountPulls: newPulls }
    case XERO_PULLS_STORE_COUNT:
      return { ...state, serverPullCount: action.payload }
    case XERO_PALS_STORE:
      const newPals = uniqueById([...action.payload, ...state.accountPals]);
      return { ...state, accountPals: newPals };
    case ACC_PAL_ADJUSTMENT_STORE:
      const newAdjustments = uniqueById([...action.payload, ...state.accPalAdjustments]);
      return { ...state, accPalAdjustments: newAdjustments };
    case ACC_PAL_ADJUSTMENT_UNSTORE:
      const filteredAdjustments = state.accPalAdjustments.filter(a => a.id !== action.payload);
      return { ...state, accPalAdjustments: filteredAdjustments };
    default:
      return state;
  };
};

const tokens = (state = initialState.tokens, action) => {
  switch (action.type) {
    case STORE_TOKEN_EXISTS:
      const newTokens = uniqueById([...action.payload, ...state.tokenExists])
      return { ...state, tokenExists: newTokens };
    case UNSTORE_TOKEN_EXISTS:
      const newState = state.tokenExists.filter(i => i.tokenId !== action.payload);
      return { ...state, tokenExists: newState };
    default:
      return state;
  };
};

const accountingThreads = (state = initialState.accountingThreads, action) => {
  switch (action.type) {
    case STORE_ACCOUNTING_THREAD_CURRENT:
      const newThreadCurrent = [...action.payload];

      return { ...state, currentStep: newThreadCurrent }
    default:
      return state;
  }
};

const reap = (state = initialState.reap, action) => {
  switch (action.type) {
    case REAP_PUT_ACCOUNTS:
      const newAccounts = uniqueById([...action.payload, ...state.accounts]);
      return { ...state, accounts: newAccounts };
    case XERO_REAP_MAPPING_PUT:
      const newMaps = uniqueById([...action.payload, ...state.mappings]);
      return { ...state, mappings: newMaps }
    case XERO_REAP_MAPPING_COMPLETION_PUT:
      const newCompletions = uniqueById([...action.payload, ...state.mappingCompletions]);
      return { ...state, mappingCompletions: newCompletions }
    default:
      return state;
  }
};

const accounting = (state = initialState.accounting, action) => {
  switch (action.type) {
    case ACC_PAL_JOINED_STORE:
      const newJoinedPals = uniqueById([...action.payload, ...state.joinedPals]);
      return { ...state, joinedPals: newJoinedPals };
    case ACC_REAP_DIVISIONAL_SPLITS_STORE:
      const newSplits = uniqueById([...action.payload, ...state.splits]);
      return { ...state, splits: newSplits };
    case ACC_PAL_GET_PRO_RATA_SPLIT_STORE:
      const newProRataSplits = uniqueById([action.payload, ...state.proRataSplits]);
      return { ...state, proRataSplits: newProRataSplits };
    case ACC_PAL_GET_PRO_RATA_SPLITS_STORE:
      const newProRataSplits2 = uniqueById([...action.payload, ...state.proRataSplits]);
      return { ...state, proRataSplits: newProRataSplits2 };
    case ACC_REAP_DIVISIONAL_SPLITS_STORE_REPLACE:
      const withoutPull = state.splits.filter(s => s.pull_id !== action.payload.pullId);
      const replacedSplits = [...action.payload.splits, ...withoutPull];
      return { ...state, splits: replacedSplits }
    case ACC_SPLIT_TEMPLATE_STORE:
      const newTemplates = uniqueById([...action.payload, ...state.splitTemplates]);
      return { ...state, splitTemplates: newTemplates }
    case ACC_SPLIT_TEMPLATE_LINES_STORE:
      const newTemplateLines = uniqueById([...action.payload, ...state.splitTemplateLines]);
      return { ...state, splitTemplateLines: newTemplateLines }
    default:
      return state;
  }
};

const reporting = (state = initialState.reporting, action) => {
  switch (action.type) {
    case REPORT_FINANCIAL_YEARS_STORE:
      if (action.payload.length === 0) {
        return state;
      }

      const without = state.financialYears.filter(i => i.office_id !== action.payload[0].office_id);
      const newYears = [...without, ...action.payload];
      return { ...state, financialYears: newYears }
    case REPORT_FIN_YEAR_HAS_DATA_STORE:
      const newFinYearHasData = uniqueById([action.payload, ...state.financialYearHasData]);
      return { ...state, financialYearHasData: newFinYearHasData }
    default:
      return state;
  }
}

const system = (state = initialState.system, action) => {
  switch (action.type) {
    case BACKGROUND_TASKS_STORE:
      const newTasks = uniqueById([...action.payload, ...state.backgroundTasks]);
      return { ...state, backgroundTasks: newTasks };
    case BACKGROUND_TASKS_STORE_COUNT:
      return { ...state, backgroundTaskCount: action.payload }    
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  accessControl,
  offices,
  signal,
  xero,
  tokens,
  accountingThreads,
  reap,
  accounting,
  reporting,
  system
});

export default rootReducer;
