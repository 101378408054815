import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { newArrayWithItem } from '../../utils/data';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { userIs } from '../../utils/jwt.js';
import { ROLE_USER } from '../../const.js';
import { useSelector } from 'react-redux';

const PalValue = (props) => {
  const jwt = useSelector(state => state.accessControl.jwt);

  const readOnly = userIs(jwt, ROLE_USER);

  const { number,
          record,
          pals,
          childAdjustments,
          setChildAdjustments,
          lineActions,
          setLineActions } = props;

  const [reviewLineActions, toggleReviewLineActions] = useState(false);

  useEffect(() => {
    for (const a of lineActions) {
      if (a.action === 'edited') {
        const [_1, _2, balanced] = getSums(a.id);
        if (balanced) {
          removeLineAction(a.id, 'edited');
          addLineAction(a.id, 'save');
        } else {
          removeLineAction(a.id, 'save');
        }
      }
    }
  }, [reviewLineActions]);

  function addChildAdjustment(value, record) {
    const amount = value === '' ? 0.0 : parseFloat(value);
    const adjustment = {
      id: record.id,
      parent: record.parent,
      amount: amount
    };
    setChildAdjustments(newArrayWithItem(childAdjustments, adjustment));
    addLineAction(record.parent, 'edited');
    toggleReviewLineActions(!reviewLineActions);
  }

  function addLineAction(id, action) {
    const la = {
      id,
      action
    };
    setLineActions([la, ...lineActions]);
  }

  function removeLineAction(id, action) {
    if (!hasLineAction(id, action)) {
      return;
    }

    const filtered = lineActions.filter(l => !(l.id === id && l.action === action));
    setLineActions(filtered);
  }

  function hasLineAction(id, action) {
    const has = lineActions.some(l => l.id === id && l.action === action);
    return has;
  }

  function renderChild() {
    const adjustment = childAdjustments.find(c => c.id === record.id);
    const currentValue = adjustment === undefined ? number : adjustment.amount;
    if (readOnly) {
      return currentValue.toFixed(2).toLocaleString();
    }
    else {
      return <Input style={{ textAlign: 'right' }}
                    defaultValue={currentValue.toFixed(2).toLocaleString()}
                    onChange={(e) => { addChildAdjustment(e.target.value, record) }} />}
  };

  function getSums(id) {
    let childSum = 0.0;
    const parentRecord = pals.find(p => p.id === id);
    const palsChildren = pals.filter(p => p.parent === id);
    for (const c of palsChildren) {
      const adjustment = childAdjustments.find(a => a.id === c.id);
      const childAmount = adjustment === undefined ? c.amount : adjustment.amount;
      childSum += childAmount;
    }

    const parentSum =  parentRecord.amount - childSum;

    const balanced = Math.abs(parentRecord.amount - childSum) < 0.001;

    return [parentSum, childSum, balanced];
  }

  function renderParentWithChildren() {
    const [parentSum, _, balanced] = getSums(record.id);

    if (balanced) {
      return parentSum.toFixed(2).toLocaleString();
    } else {
      return (
        <span>
          <ExclamationCircleOutlined style={{ color: 'red', marginRight: '20px' }} />
          {parentSum.toFixed(2).toLocaleString()}
        </span>
      );
    }
  }

  function renderChildlessParent() {
    return record.amount.toFixed(2).toLocaleString();
  }

  function renderParent() {
    const hasChildren = pals.some(p => p.parent === record.id);
    if (hasChildren) {
      return renderParentWithChildren();
    }
    else {
      return renderChildlessParent();
    }
  };

  function render() {
    if (record.child) {
      return renderChild();
    }
    else {
      return renderParent();
    }
  }

  return render();
}

export default PalValue;
