import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Typography, Col, Row, Select, Switch, Tag, Button } from 'antd';
import { action, REPORT_FINANCIAL_YEARS_GET } from '../../state/actions.js';
import FinancialYearReport from './FinancialYearReport.js';

const { Option } = Select;
const { Title } = Typography;

function FinancialYearReportView(props) {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();

  const officeId = searchParams.get("officeId");

  const financialYears = useSelector(state => state.reporting.financialYears)
        .filter(i => i.office_id === officeId);

  const [year, setYear] = useState(null);
  const [color, setColor] = useState(true);
  const [enableSales, setEnableSales] = useState(true);
  const [enablePm, setEnablePm] = useState(true);
  const [enableDir, setEnableDir] = useState(true);
  const [showNamesAlways, setShowNamesAlways] = useState(false);

  function onFinancialYearChanged(year) {
    setYear(year);
  }

  useEffect(() => {
    dispatch(action(REPORT_FINANCIAL_YEARS_GET, { officeId }));
  }, [dispatch, officeId]);

  function printableVersionClicked() {
    const params = new URLSearchParams();
    params.append("officeId", officeId);
    params.append("year", year);
    params.append("color", color);
    params.append("enableSales", enableSales);
    params.append("enablePm", enablePm);
    params.append("enableDir", enableDir);
    params.append("showNamesAlways", showNamesAlways);

    const url = `/reports/financial-year-print?${params.toString()}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={3}>Financial Year</Title>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Select
            showSearch
            style={{ width: 250 }}
            onChange={onFinancialYearChanged}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}>

            {
              financialYears.map(i => {
                return <Option key={i.year} value={i.year} label={i.year}>{i.year}</Option>
              })
            }
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <p><Tag>Color</Tag> <Switch defaultChecked size="small" onChange={(checked) => setColor(checked)}/></p>
        </Col>
        <Col span={2}>
          <p><Tag>Sales</Tag> <Switch defaultChecked size="small" onChange={(checked) => setEnableSales(checked)}/></p>
        </Col>
        <Col span={2}>
          <p><Tag>PM</Tag> <Switch defaultChecked size="small" onChange={(checked) => setEnablePm(checked)}/></p>
        </Col>
        <Col span={2}>
          <p><Tag>Dir</Tag> <Switch defaultChecked size="small" onChange={(checked) => setEnableDir(checked)} /></p>
        </Col>
        <Col span={2}>
          <p><Tag>Repeat Titles</Tag> <Switch size="small" onChange={(checked) => setShowNamesAlways(checked)} /></p>
        </Col>
        <Col span={2}>
          <Button type="primary" size="small" style={{marginTop: '12px'}} onClick={printableVersionClicked} disabled={year === null}>Printable Version</Button>
        </Col>
      </Row>
      <Row>
        <FinancialYearReport
          year={year}
          color={color}
          enableSales={enableSales}
          enablePm={enablePm}
          enableDir={enableDir}
          showNamesAlways={showNamesAlways}
        />
      </Row>
    </div>
  );
}

export default FinancialYearReportView;
