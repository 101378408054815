import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { action, GET_OFFICE_ALL } from '../../state/actions.js';
import { getStateFromCode, getCountryFromCode } from "../../state/countries.js";
import { Table, Tag } from 'antd';
import { Link } from "react-router-dom";

const OverviewOffice = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const offices = useSelector(state => state.offices.items);
  const serverOfficeCount = useSelector(state => state.offices.serverCount);

  useEffect(() => {
    dispatch(action(GET_OFFICE_ALL, page));
  }, [page, dispatch]);

  const onPagination = (page) => {
    setPage(page - 1);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        const url = `/office/view?id=${row.id}`
        return (
          <Link to={url}>{row.name}</Link>
        );
      }
    },
    {
       title: 'Street',
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: 'Post Code',
      dataIndex: 'post_code',
      key: 'post_code',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state, row) => {
        return (
          <p>{getStateFromCode(row.country, state)}</p>
        );
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (country) => {
        return (
          <Tag color="green">{getCountryFromCode(country)}</Tag>
        );
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  return (
    <div>
      <Table rowKey='id'
             dataSource={offices}
             columns={columns}
             size="small"
             pagination={{ 'defaultCurrent': 1, 'total': serverOfficeCount, 'onChange': onPagination }} />
    </div>
  );
};

export default OverviewOffice;
