import jwt_decode from "jwt-decode";

const jwtValid = (jwt) => {
    if (!jwt) {
        return false;
    }

    if (jwt === '') {
        return false;
    }

    return true;
}

const userIs = (jwt, role) => {
  const jwtDecoded = jwt_decode(jwt);

  if (jwtDecoded.role === role) {
    return true;
  }

  return false;
};

export  {
  jwtValid,
  userIs
}
