import React, { useState } from 'react';
import { Typography, Button, DatePicker, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { action, ACCOUNTING_PERIOD_SCHEDULE_YEAR } from '../../state/actions.js';
import { useNavigate, createSearchParams } from 'react-router-dom';

function ScheduleYear(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);
  const [date, setDate] = useState(null);

  function onChangeDate(_, newDate) {
    setDate(newDate);
    setEnabled(true);
  }

  function onQueueAccountingPeriod() {
    dispatch(action(ACCOUNTING_PERIOD_SCHEDULE_YEAR, {
      officeId: props.officeId,
      date
    }));

    const params = {
      id: props.officeId
    };

    navigate({
      pathname: '/office/view',
      search: createSearchParams(params).toString()
    });
  }

  return (
    <div>
      <Space direction="vertical" size={12}>
        <Typography.Title level={3}>Schedule Accounts Pull For Financial Year</Typography.Title>
        <DatePicker picker="year" onChange={onChangeDate} />
        <Button type='primary' disabled={!enabled}
          onClick={onQueueAccountingPeriod}>Queue</Button>
      </Space>
    </div>
  );
};

export default ScheduleYear;
