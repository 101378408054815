import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Radio } from 'antd';
import ScheduleMonth from './ScheduleMonth.js';
import ScheduleYear from './ScheduleYear.js';

function ScheduleAccountingPeriod() {
  const [searchParams, _] = useSearchParams();

  const [value, setValue] = useState('month');

  const officeId = searchParams.get("officeId");

  function onChange(e) {
    setValue(e.target.value);
  }

  function renderRangeSelector() {
    if (value === 'month') {
      return <ScheduleMonth officeId={officeId}/>
    } else if (value === 'year') {
      return <ScheduleYear officeId={officeId}/>
    }
  }

  return (
    <div>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value='month'>Schedule by month.</Radio>
        <Radio value='year'>Schedule by year.</Radio>
      </Radio.Group>
      {renderRangeSelector()}
    </div>
  );
};

export default ScheduleAccountingPeriod;
